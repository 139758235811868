import { delay } from 'redux-saga'
import { call, select, put } from 'redux-saga/effects'
import moment from 'moment'
import _ from 'lodash'

import { getSession, getService } from '../reducers/selectors'
import ServiceActions from '../reducers/service'
import SessionActions from '../reducers/session'
import ModalActions from '../reducers/modal'

import { USER_INFO, ERRORS, ALLOWED_CITIES } from '../data/constants'

const filterAddressByProperty = (listAddress, property) =>
  _.filter(listAddress, a =>
    Object.prototype.hasOwnProperty.call(a, property),
  )

const validateAddress = address =>
  Object.keys(address).length === 1 || (!address.lat || !address.lon)

function* saveRoute(result) {
  if (result.status === 200 && result.ok) {
    yield put(ServiceActions.setRoute(result))
  }
}

const normalizeString = string => {
  let s = string.toLowerCase()
  s = s.replace(/á/gi, 'a')
  s = s.replace(/é/gi, 'e')
  s = s.replace(/í/gi, 'i')
  s = s.replace(/ó/gi, 'o')
  s = s.replace(/ú/gi, 'u')
  const stringFormated = s.split(' ')
  return stringFormated[0]
}

const filterAllowedCities = cities =>
  _.filter(cities, o =>
    normalizeString(o.city).includes(ALLOWED_CITIES),
  )

// const validateCity = (city, cities) =>
//   city
//     ? _.filter(cities, o =>
//         normalizeString(o.city).includes(normalizeString(city)),
//       )
//     : [];

export function* searchGeoSuggests(api, { address, source }) {
  yield put(ServiceActions.loading())
  const session = yield select(getSession)
  // const service = yield select(getService);
  const result = yield call(api.getGeoLiftit, address, session.country)
  if (result.status === 200 && result.data.results) {
    const suggest = filterAddressByProperty(
      result.data.results,
      'dep_state',
    )
    if (source === 'origin') {
      // const destinationCity = service.currentDestinationAddress.city;
      // if (destinationCity) {
      //   const filteredOriginResults = _.filter(suggest, o => normalizeString(o.city).includes(normalizeString(destinationCity)));
      //   yield put(ServiceActions.setGeoSuggestOrigin(filteredOriginResults));
      // } else {
      const filteredOriginResults = filterAllowedCities(suggest)
      yield put(
        ServiceActions.setGeoSuggestOrigin(filteredOriginResults),
      )
      // }
    } else {
      // const originCity = service.currentOriginAddress.city;
      // if (originCity) {
      //   const filteredDestinationResults = _.filter(suggest, o => normalizeString(o.city).includes(normalizeString(originCity))); // TODO: Eliminar cuando se habiliten servicios nacionales.
      //   yield put(ServiceActions.setGeoSuggestDestination(filteredDestinationResults));
      // } else {
      const filteredDestinationResults = filterAllowedCities(suggest)
      yield put(
        ServiceActions.setGeoSuggestDestination(
          filteredDestinationResults,
        ),
      )
      // }
    }
  }
  yield call(delay, 2000)
  yield put(ServiceActions.noFetching())
}

export function* searchByDirectAddress(api, { address, source }) {
  yield call(delay, 1000)
  const {
    currentOriginAddress,
    currentDestinationAddress,
  } = yield select(getService)
  if (
    (source === 'origin' && validateAddress(currentOriginAddress)) ||
    (source === 'destination' &&
      validateAddress(currentDestinationAddress))
  ) {
    yield put(ServiceActions.loading())
    const session = yield select(getSession)
    const result = yield call(
      api.getGeoLiftit,
      address,
      session.country,
    )
    if (result.status === 200 && result.data.results) {
      const suggest = filterAddressByProperty(
        result.data.results,
        'dep_state',
      )
      if (suggest && suggest.length > 0) {
        if (source === 'origin') {
          yield put(ServiceActions.noFetching())
          // if (Object.keys(currentDestinationAddress).length > 1 && currentDestinationAddress.lat && currentDestinationAddress.lon) {
          //   const cities = validateCity(currentDestinationAddress.city, suggest)
          //   if (cities && cities.length > 0) {
          //     yield put(ServiceActions.setCurrentOriginAddress(cities[0]));
          //   }
          // } else {
          const filteredOriginResults = filterAllowedCities(suggest)
          yield put(
            ServiceActions.setCurrentOriginAddress(
              filteredOriginResults[0],
            ),
          )
          // }
          yield put(ServiceActions.resetSearchGeoSuggestOrigin())
        } else {
          yield put(ServiceActions.noFetching())
          // if (Object.keys(currentOriginAddress).length > 1 && currentOriginAddress.lat && currentOriginAddress.lon) {
          //   const cities = validateCity(currentOriginAddress.city, suggest)
          //   if (cities && cities.length > 0) {
          //     yield put(ServiceActions.setCurrentDestinationAddress(cities[0]));
          //   }
          // } else {
          const filteredDestinationResults = filterAllowedCities(
            suggest,
          )
          yield put(
            ServiceActions.setCurrentDestinationAddress(
              filteredDestinationResults[0],
            ),
          )
          // }
          yield put(ServiceActions.resetSearchGeoSuggestDestination())
        }
      } else {
        yield put(ServiceActions.noFetching())
      }
    } else {
      yield put(ServiceActions.noFetching())
    }
  }
}

export function* routeByOrigin(api, { address }) {
  const { currentDestinationAddress } = yield select(getService)
  if (currentDestinationAddress.lat && currentDestinationAddress.lon) {
    const result = yield call(
      api.getRoute,
      `${address.lat},${address.lon}`,
      `${currentDestinationAddress.lat},${
        currentDestinationAddress.lon
      }`,
    )
    yield saveRoute(result)
  }
}

export function* routeByDestination(api, { address }) {
  const { currentOriginAddress } = yield select(getService)
  if (currentOriginAddress.lat && currentOriginAddress.lon) {
    const result = yield call(
      api.getRoute,
      `${currentOriginAddress.lat},${currentOriginAddress.lon}`,
      `${address.lat},${address.lon}`,
    )
    yield saveRoute(result)
  }
}

export function* createService(api, action) {
  yield put(ServiceActions.loading())
  const currenService = yield select(
    state => state.service.currentService,
  )
  const currentOriginAddress = yield select(
    state => state.service.currentOriginAddress,
  )
  const currentDestinationAddress = yield select(
    state => state.service.currentDestinationAddress,
  )
  const insuredAmount = yield select(
    state => state.service.insuredAmount,
  )
  const details = yield select(state => state.service.details)
  const userInfo = yield select(state => state.session.user)
  const phoneNumber = yield select(state => state.session.phoneNumber)

  const user =
    userInfo && Object.keys(userInfo).length > 0
      ? Object.assign({}, userInfo, { phone: phoneNumber })
      : {}
  const scheduledTime = yield select(state => state.service.dateAndHour)
  const photos = yield select(state => state.service.photos)
  const serviceInfo = yield select(state => state.service.serviceInfo)
  const currentStep = yield select(state => state.service.currentStep)
  const paid = yield select(state => state.service.paid)
  const paymentId = yield select(state => state.service.paymentId)
  const paymentType = yield select(state => state.service.paymentType)
  const { tokenAuth } = yield select(getSession)
  const data = {
    id:
      serviceInfo && serviceInfo.data && serviceInfo.data[0]
        ? serviceInfo.data[0].id
        : null,
    service_type_id: currenService.id || 50,
    tasks: [
      {
        task_type: 'l',
        description: details,
        address: currentOriginAddress.formatted_address,
        city: currentOriginAddress.city || '',
        dep_state:
          currentOriginAddress.department ||
          currentOriginAddress.city ||
          '',
        country: currentOriginAddress.country || '',
        lat: currentOriginAddress.lat,
        lon: currentOriginAddress.lon,
        recipient:
          user && Object.keys(user).length === 0 ? USER_INFO : user,
        meta: { photos, paid, paymentId, paymentType },
      },
      {
        task_type: 'u',
        description: details,
        address: currentDestinationAddress.formatted_address,
        city: currentDestinationAddress.city || '',
        dep_state: currentDestinationAddress.department || '',
        country: currentDestinationAddress.country || '',
        lat: currentDestinationAddress.lat,
        lon: currentDestinationAddress.lon,
        recipient:
          user && Object.keys(user).length === 0 ? USER_INFO : user,
        max_weight: currenService.maxWeight,
        max_volume: currenService.maxVolume,
      },
    ],
    num_operators: 0,
    insuredAmount,
  }

  if (scheduledTime !== '') {
    data.scheduled_time = scheduledTime
    data.scheduled_time_until = moment(scheduledTime)
      .add('hours', 1)
      .format()
  }
  const response = yield call(api.createService, data, tokenAuth)
  switch (response.status) {
    case 200:
    case 201: {
      yield put(ServiceActions.setService(response))
      yield put(ServiceActions.setValidService(true))
      if (!action.avoidNextStep) {
        yield data.id &&
          put(ServiceActions.setCurrentStep(currentStep + 1))
      }
      yield put(ServiceActions.noFetching())
      window.scrollTo(0, 0)
      const serviceCreated = response.data[0]
      if (
        serviceCreated.tasks &&
        serviceCreated.tasks[0] &&
        serviceCreated.tasks[0].recipient
      ) {
        const { recipient } = serviceCreated.tasks[0]
        const {
          email,
          id,
          name,
          numDoc,
          phone,
          phoneCode,
          meta,
        } = recipient
        if (email !== 'liftit.user@liftit.co') {
          yield put({
            type: 'TRACK_ON_MIXPANEL',
            analytics: {
              eventType: 'track',
              eventPayload: {
                event: 'USER_INFORMATION_ADDED',
                properties: {
                  $email: email,
                  $name: name,
                  $phone: phone,
                  user_email: email,
                  user_liftit_id: id,
                  user_name: name,
                  user_numDoc: numDoc,
                  user_phone: phone,
                  user_phoneCode: phoneCode,
                  service_meta: meta,
                },
              },
            },
          })
        }
      }
      yield put({
        type: 'TRACK_ON_MIXPANEL',
        analytics: {
          eventType: 'track',
          eventPayload: {
            event: 'SERVICE_UPDATED',
            properties: {
              service_id: serviceCreated.id,
              estimated_price: serviceCreated.estimated_price,
              uuid: serviceCreated.uuid,
            },
          },
        },
      })
      break
    }
    default: {
      const { modalContent } = ERRORS
      yield put(SessionActions.logout())
      yield put(ServiceActions.noFetching())
      yield put(ServiceActions.setValidService(false))
      if (
        response.data &&
        response.data.errors &&
        response.data.errors.code
      ) {
        modalContent.text =
          ERRORS[response.data.errors.code] !== undefined
            ? ERRORS[response.data.errors.code]
            : modalContent.text
        if (response.data.errors.code === 1000) {
          yield put(
            ModalActions.setModalContent({
              ...modalContent,
              link: () => {
                history.push({ pathname: '/' }) // eslint-disable-line no-restricted-globals
                dispatch(ModalActions.closeModal()) // eslint-disable-line no-undef
                dispatch(ServiceActions.cleanState()) // eslint-disable-line no-undef
              },
              close: () => {
                history.push({ pathname: '/' }) // eslint-disable-line no-restricted-globals
                dispatch(ModalActions.closeModal()) // eslint-disable-line no-undef
                dispatch(ServiceActions.cleanState()) // eslint-disable-line no-undef
              },
            }),
          )
        } else {
          yield put(ModalActions.setModalContent(modalContent))
        }
        yield put({
          type: 'TRACK_ON_MIXPANEL',
          analytics: {
            eventType: 'track',
            eventPayload: {
              event: 'API_ERROR',
              properties: {
                code:
                  response.data && response.data.errors
                    ? response.data.errors.code
                    : '',
                message:
                  response.data && response.data.errors
                    ? response.data.errors.message
                    : '',
              },
            },
          },
        })
      } else {
        yield put(ModalActions.setModalContent(modalContent))
      }
      break
    }
  }
}

export function* createServiceLiftit(api, action) {
  const {
    currentOriginAddress,
    currentDestinationAddress,
  } = yield select(getService)
  if (
    currentOriginAddress.lat &&
    currentOriginAddress.lon &&
    currentDestinationAddress.lat &&
    currentDestinationAddress.lon
  ) {
    yield createService(api, action)
  }
}

export function* createServiceFinal(api, action) {
  yield put(ServiceActions.loading())
  const currenService = yield select(
    state => state.service.currentService,
  )
  const currentOriginAddress = yield select(
    state => state.service.currentOriginAddress,
  )
  const currentDestinationAddress = yield select(
    state => state.service.currentDestinationAddress,
  )
  const insuredAmount = yield select(
    state => state.service.insuredAmount,
  )
  const details = yield select(state => state.service.details)
  const userInfo = yield select(state => state.session.user)
  const phoneNumber = yield select(state => state.session.phoneNumber)

  const user =
    userInfo && Object.keys(userInfo).length > 0
      ? Object.assign({}, userInfo, { phone: phoneNumber })
      : {}

  const scheduledTime = yield select(state => state.service.dateAndHour)
  const photos = yield select(state => state.service.photos)
  const serviceInfo = yield select(state => state.service.serviceInfo)
  const currentStep = yield select(state => state.service.currentStep)
  const paid = yield select(state => state.service.paid)
  const paymentId = yield select(state => state.service.paymentId)
  const { tokenAuth } = yield select(getSession)
  const data = {
    id:
      serviceInfo && serviceInfo.data && serviceInfo.data[0]
        ? serviceInfo.data[0].id
        : null,
    user_id: userInfo && userInfo.id,
    customer_id: userInfo && userInfo.id,
    service_type_id: currenService.id || 50,
    tasks: [
      {
        task_type: 'l',
        description: details,
        address: currentOriginAddress.formatted_address,
        city: currentOriginAddress.city || '',
        dep_state:
          currentOriginAddress.department ||
          currentOriginAddress.city ||
          '',
        country: currentOriginAddress.country || '',
        lat: currentOriginAddress.lat,
        lon: currentOriginAddress.lon,
        recipient: Object.keys(user).length === 0 ? USER_INFO : user,
        meta: { photos, paid, paymentId },
      },
      {
        task_type: 'u',
        description: details,
        address: currentDestinationAddress.formatted_address,
        city: currentDestinationAddress.city || '',
        dep_state: currentDestinationAddress.department || '',
        country: currentDestinationAddress.country || '',
        lat: currentDestinationAddress.lat,
        lon: currentDestinationAddress.lon,
        recipient: Object.keys(user).length === 0 ? USER_INFO : user,
        max_weight: currenService.maxWeight,
        max_volume: currenService.maxVolume,
      },
    ],
    num_operators: 0,
    insuredAmount,
  }

  if (scheduledTime !== '') {
    data.scheduled_time = scheduledTime
    data.scheduled_time_until = moment(scheduledTime)
      .add('hours', 1)
      .format()
  }
  const response = yield call(api.createService, data, tokenAuth)
  switch (response.status) {
    case 200:
    case 201: {
      yield put(ServiceActions.setService(response))
      if (!action.avoidNextStep) {
        yield data.id &&
          put(ServiceActions.setCurrentStep(currentStep + 1))
      }
      yield put(ServiceActions.runService())
      window.scrollTo(0, 0)
      const serviceCreated = response.data[0]
      yield put({
        type: 'TRACK_ON_MIXPANEL',
        analytics: {
          eventType: 'track',
          eventPayload: {
            event: 'SERVICE_COTIZED',
            properties: {
              service_id: serviceCreated.id,
              estimated_price: serviceCreated.estimated_price,
              uuid: serviceCreated.uuid,
            },
          },
        },
      })
      break
    }
    default: {
      const { modalContent } = ERRORS
      yield put({
        type: 'TRACK_ON_MIXPANEL',
        analytics: {
          eventType: 'track',
          eventPayload: {
            event: 'API_ERROR',
            properties: {
              code:
                response.data && response.data.errors
                  ? response.data.errors.code
                  : '',
              message:
                response.data && response.data.errors
                  ? response.data.errors.message
                  : '',
            },
          },
        },
      })
      yield put(ServiceActions.noFetching())
      if (
        response.data &&
        response.data.errors &&
        response.data.errors.code
      ) {
        modalContent.text =
          ERRORS[response.data.errors.code] !== undefined
            ? ERRORS[response.data.errors.code]
            : modalContent.text
        if (response.data.errors.code === 1000) {
          yield put(
            ModalActions.setModalContent({
              ...modalContent,
              link: () => {
                history.push({ pathname: '/' }) // eslint-disable-line no-restricted-globals
                dispatch(ModalActions.closeModal()) // eslint-disable-line no-undef
                dispatch(ServiceActions.cleanState()) // eslint-disable-line no-undef
              },
              close: () => {
                history.push({ pathname: '/' }) // eslint-disable-line no-restricted-globals
                dispatch(ModalActions.closeModal()) // eslint-disable-line no-undef
                dispatch(ServiceActions.cleanState()) // eslint-disable-line no-undef
              },
            }),
          )
        } else {
          yield put(ModalActions.setModalContent(modalContent))
        }
        if (response.data.errors.code === 1005) {
          ServiceActions.setCurrentStep(0)
        }
      } else {
        yield put(ModalActions.setModalContent(modalContent))
      }
      break
    }
  }
}

export function* runService(api) {
  const idService = yield select(
    state => state.service.serviceInfo.data[0].id,
  )
  const dataNotifyPubnub = yield select(
    state => state.service.notifyPubnub,
  )
  dataNotifyPubnub.tracking = `${process.env.REACT_APP_TRACKING_URL}`
  const { tokenAuth } = yield select(getSession)

  const response = yield call(api.runService, idService, tokenAuth)
  switch (response.status) {
    case 200:
    case 201: {
      yield put(ServiceActions.setServiceStarted(response))
      yield call(
        api.sendSMS,
        { notification_data: dataNotifyPubnub },
        tokenAuth,
      )
      break
    }
    default: {
      const { modalContent } = ERRORS
      yield put(ServiceActions.noFetching())
      if (
        response.data &&
        response.data.errors &&
        response.data.errors.code
      ) {
        modalContent.text =
          ERRORS[response.data.errors.code] !== undefined
            ? ERRORS[response.data.errors.code]
            : modalContent.text
        if (response.data.errors.code === 1000) {
          yield put(
            ModalActions.setModalContent({
              ...modalContent,
              link: () => {
                history.push({ pathname: '/' }) // eslint-disable-line no-restricted-globals
                dispatch(ModalActions.closeModal()) // eslint-disable-line no-undef
                dispatch(ServiceActions.cleanState()) // eslint-disable-line no-undef
              },
              close: () => {
                history.push({ pathname: '/' }) // eslint-disable-line no-restricted-globals
                dispatch(ModalActions.closeModal()) // eslint-disable-line no-undef
                dispatch(ServiceActions.cleanState()) // eslint-disable-line no-undef
              },
            }),
          )
        } else {
          yield put(ModalActions.setModalContent(modalContent))
        }
        yield put({
          type: 'TRACK_ON_MIXPANEL',
          analytics: {
            eventType: 'track',
            eventPayload: {
              event: 'API_ERROR',
              properties: {
                code:
                  response.data && response.data.errors
                    ? response.data.errors.code
                    : '',
                message:
                  response.data && response.data.errors
                    ? response.data.errors.message
                    : '',
              },
            },
          },
        })
      } else {
        yield put(ModalActions.setModalContent(modalContent))
      }
      break
    }
  }
}

export function* updateService(api, action) {
  const { service } = action
  const { serviceInfo } = yield select(getService)
  if (
    serviceInfo &&
    serviceInfo.data &&
    serviceInfo.data[0] &&
    serviceInfo.data[0].service_type &&
    serviceInfo.data[0].service_type.id &&
    serviceInfo.data[0].service_type.id !== service.id
  ) {
    yield createServiceLiftit(api, action)
  }
}

export function* setServiceFromId({ serviceTypeId, t }) {
  const { serviceTypes } = yield select(getService)
  if (serviceTypes && Array.isArray(serviceTypes)) {
    const serviceType = serviceTypes.find(
      x => parseInt(x.id, 10) === parseInt(serviceTypeId, 10),
    )
    if (serviceType) {
      const serviceTypeIntern = Object.assign({}, serviceType, {
        title: serviceType.title,
        description: t(
          `components:serviceTypes.types.${serviceType.description}`,
        ),
        totalDescription: t(
          `components:serviceTypes.types.${
            serviceType.totalDescription
          }`,
        ),
      })
      yield put(ServiceActions.setSelectService(serviceTypeIntern))
    } else {
      yield put(ServiceActions.cleanCurrentService())
    }
  }
}

export function* sendConfirmationMessage(api, action) {
  const { countryCode, recipientPhone, message } = action
  const { tokenAuth } = yield select(getSession)
  yield call(
    api.sendSMS,
    { notification_data: { countryCode, recipientPhone, message } },
    tokenAuth,
  )
}
