import apisauce from 'apisauce'

import { REACT_APP_GEO_AWS_URL } from '../data/constants'

const create = (baseURL = REACT_APP_GEO_AWS_URL) => {
  const api = apisauce.create({
    baseURL,
    timeout: 10000,
    headers: {
      'Content-Type': 'application/json;odata=verbose',
    },
  })

  const getGeoLiftit = (address, country) =>
    // api.get(`liftitGeocodeApi?address=${encodeURIComponent(address)}&country=${country}`);
    api.get(
      `DeployWithSandbox/sandbox?address=${encodeURIComponent(
        address,
      )}&country=${country}`,
    )

  return {
    getGeoLiftit,
  }
}

// let's return back our create method as the default.
export default {
  create,
}
