import _ from 'lodash'
import { call, put, select } from 'redux-saga/effects'

import SessionActions from '../reducers/session'
import ServiceActions from '../reducers/service'
import ModalActions from '../reducers/modal'
import { getSession } from '../reducers/selectors'
import {
  AUTHORIZATION_TOKEN_COL,
  AUTHORIZATION_TOKEN_MEX,
  AUTHORIZATION_TOKEN_CHL,
  AUTHORIZATION_TOKEN_ECU,
  AUTHORIZATION_TOKEN_BRA,
} from '../data/constants'

const getUniqueId = state => state.session.uniqueId

export function* closeModal() {
  yield put(ModalActions.closeModal())
}

export function* getLoaderVistas(api) {
  const result = yield call(api.Vistas().getVistas)
  if (result && result.status === 200 && result.ok) {
    yield put(SessionActions.setLoaderVistas(result.data))
  }
}
export function* getLoaderBoxfoalt(api) {
  const result = yield call(api.Boxfoalts().getBoxfoalts)
  if (result && result.status === 200 && result.ok) {
    yield put(SessionActions.setLoaderBoxfoalts(result.data))
  }
}
export function* getLoaderBanner(api) {
  const result = yield call(api.Banner().getBanner)
  if (result && result.status === 200 && result.ok) {
    yield put(SessionActions.setLoaderBanner(result.data))
  }
}
export function* getLoaderTypeText(api) {
  const result = yield call(api.TypeText().getTypeText)
  if (result && result.status === 200 && result.ok) {
    yield put(SessionActions.setLoaderTypeText(result.data))
  }
}
export function* getLoaderMyTexts(api) {
  const result = yield call(api.MyTexts().getMyTexts)
  if (result && result.status === 200 && result.ok) {
    yield put(SessionActions.setLoaderMyTexts(result.data))
  }
}
export function* getLoaderBlog(api) {
  const result = yield call(api.Blog().getBlog)
  if (result && result.status === 200 && result.ok) {
    yield put(SessionActions.setLoaderBlog(result.data))
  }
}

export function* getLoaderOurClients(api) {
  const result = yield call(api.OurClients().getOurClients)
  if (result && result.status === 200 && result.ok) {
    yield put(SessionActions.setLoaderOurClients(result.data))
  }
}
export function* getLoaderMenu(api) {
  const result = yield call(api.Menu().getMenu)
  if (result && result.status === 200 && result.ok) {
    yield put(SessionActions.setLoaderMenu(result.data))
  }
}
export function* getLoaderFotter(api) {
  const result = yield call(api.Fotter().getFotter)
  if (result && result.status === 200 && result.ok) {
    yield put(SessionActions.setLoaderFotter(result.data))
  }
}
export function* getLoaderLinks(api) {
  const result = yield call(api.Links().getLinks)
  if (result && result.status === 200 && result.ok) {
    yield put(SessionActions.setLoaderLinks(result.data))
  }
}
export function* getLoaderBotonesMenu(api) {
  const result = yield call(api.BotonesMenu().getBotonesMenu)
  if (result && result.status === 200 && result.ok) {
    yield put(SessionActions.setLoaderBotonesMenu(result.data))
  }
}
export function* getLoaderSliber(api) {
  const result = yield call(api.Slider().getSlider)
  if (result && result.status === 200 && result.ok) {
    yield put(SessionActions.setLoaderSliber(result.data))
  }
}
export function* getLoaderContSliderLogistica(api) {
  const result = yield call(
    api.ContSliderLogistica().getContSliderLogistica,
  )
  if (result && result.status === 200 && result.ok) {
    yield put(SessionActions.setContSliderLogistica(result.data))
  }
}
export function* getLoaderContSliderHitos(api) {
  const result = yield call(api.ContSliderHitos().getContSliderHitos)
  if (result && result.status === 200 && result.ok) {
    yield put(SessionActions.setContSliderHitos(result.data))
  }
}
export function* getLoaderContSliderBlog(api) {
  const result = yield call(api.ContSliderBlog().getContSliderBlog)
  if (result && result.status === 200 && result.ok) {
    yield put(SessionActions.setContSliderBlog(result.data))
  }
}
export function* getLoaderContArticuloTwoType(api) {
  const result = yield call(
    api.ContArticuloTwoType().getContArticuloTwoType,
  )
  if (result && result.status === 200 && result.ok) {
    yield put(SessionActions.setContArticuloTwoType(result.data))
  }
}
export function* getLoaderConfFloatBlog(api) {
  const result = yield call(api.ConfFloatBlog().getConfFloatBlog)
  if (result && result.status === 200 && result.ok) {
    yield put(SessionActions.setConfFloatBlog(result.data))
  }
}
export function* getLoaderContArticuloColum(api) {
  const result = yield call(
    api.ContArticuloColum().getContArticuloColum,
  )
  if (result && result.status === 200 && result.ok) {
    yield put(SessionActions.setContArticuloColum(result.data))
  }
}
export function* getLoaderContTextTwoImg(api) {
  const result = yield call(api.ContTextTwoImg().getContTextTwoImg)
  if (result && result.status === 200 && result.ok) {
    yield put(SessionActions.setContTextTwoImg(result.data))
  }
}
export function* getLoaderContArticuloWhite(api) {
  const result = yield call(
    api.ContArticuloWhite().getContArticuloWhite,
  )
  if (result && result.status === 200 && result.ok) {
    yield put(SessionActions.setContArticuloWhite(result.data))
  }
}
export function* getLoaderContVideo(api) {
  const result = yield call(api.ContVideo().getContVideo)
  if (result && result.status === 200 && result.ok) {
    yield put(SessionActions.setContVideo(result.data))
  }
}
export function* getLoaderContClientes(api) {
  const result = yield call(api.ContClientes().getContClientes)
  if (result && result.status === 200 && result.ok) {
    yield put(SessionActions.setContClientes(result.data))
  }
}
export function* getLoaderContArticulosh(api) {
  const result = yield call(api.ContArticulosh().getContArticulosh)
  if (result && result.status === 200 && result.ok) {
    yield put(SessionActions.setContArticulosh(result.data))
  }
}
export function* getUserLocation(api) {
  const result = yield call(api.getLocation)
  if (result && result.status === 200 && result.ok) {
    if (result.data) {
      const countryGoogle = result.data.country
      let country = ''
      let phoneCode = ''
      let currency = ''
      let organizationId = 0
      const automatic = true
      let latitude = 4.70712
      let longitude = -74.045654
      switch (countryGoogle) {
        case 'CO':
          country = 'co'
          phoneCode = '+57'
          currency = 'COP'
          organizationId = parseInt(process.env.ORGANIZATION_COL, 10)
          break
        case 'EC':
          country = 'ec'
          phoneCode = '+593'
          currency = 'USD'
          organizationId = parseInt(process.env.ORGANIZATION_ECU, 10)
          latitude = -0.225219
          longitude = -78.5248

          break
        case 'BR':
          country = 'br'
          phoneCode = '+55'
          currency = 'BRL'
          organizationId = parseInt(process.env.ORGANIZATION_BRA, 10)
          latitude = -23.5475
          longitude = -46.63611
          break
        case 'MX':
          country = 'mx'
          phoneCode = '+521'
          currency = 'MXN'
          organizationId = parseInt(process.env.ORGANIZATION_MEX, 10)
          latitude = 19.4978
          longitude = -99.1269
          break
        case 'CL':
          country = 'cl'
          phoneCode = '+56'
          currency = 'CLP'
          organizationId = parseInt(process.env.ORGANIZATION_CHL, 10)
          latitude = -33.47269
          longitude = -70.64724
          break
        default:
          country = 'co'
          phoneCode = '+57'
          currency = 'COP'
          organizationId = parseInt(process.env.ORGANIZATION_COL, 10)
          break
      }
      yield put(
        SessionActions.setCountry(
          country,
          phoneCode,
          currency,
          automatic,
          organizationId,
          latitude,
          longitude,
        ),
      )
      yield put(SessionActions.setCountryOrigin(countryGoogle))
    }
  }
}

export function* identify() {
  const currentUniqueId = yield select(getUniqueId)
  const country = yield select(state => state.session.country)
  const userInfo = yield select(state => state.session.user)
  yield put({
    type: 'TRACK_ON_MIXPANEL',
    analytics: {
      eventType: 'identify',
      eventPayload: {
        userId: currentUniqueId,
        $email: userInfo.email,
        $name: userInfo.name,
        $phone: userInfo.phone,
        traits: {
          name: userInfo.name,
          email: userInfo.email,
          $phone: userInfo.phone,
          country: country.toUpperCase(),
        },
      },
    },
  })
}

export function* setToken({ country }) {
  switch (country) {
    case 'co': {
      yield put(SessionActions.setToken(AUTHORIZATION_TOKEN_COL))
      break
    }
    case 'mx': {
      yield put(SessionActions.setToken(AUTHORIZATION_TOKEN_MEX))
      break
    }
    case 'cl': {
      yield put(SessionActions.setToken(AUTHORIZATION_TOKEN_CHL))
      break
    }
    case 'ec': {
      yield put(SessionActions.setToken(AUTHORIZATION_TOKEN_ECU))
      break
    }
    case 'br': {
      yield put(SessionActions.setToken(AUTHORIZATION_TOKEN_BRA))
      break
    }
    default: {
      yield put(SessionActions.setToken(AUTHORIZATION_TOKEN_COL))
      break
    }
  }
  const currentUniqueId = yield select(getUniqueId)
  const userInfo = yield select(state => state.session.user)
  if (userInfo) {
    if (currentUniqueId === null) {
      const uniqueId = Math.random()
        .toString(36)
        .slice(2)
      yield put(SessionActions.setUniqueId(uniqueId))
      yield put({
        type: 'TRACK_ON_MIXPANEL',
        analytics: {
          eventType: 'identify',
          eventPayload: {
            traits: {
              name: userInfo.name,
              email: userInfo.email,
              $phone: userInfo.phone,
              country: country.toUpperCase(),
            },
            $phone: userInfo.phone,
            $email: userInfo.email,
            $name: userInfo.name,
            userId: uniqueId,
          },
        },
      })
    } else {
      yield put({
        type: 'TRACK_ON_MIXPANEL',
        analytics: {
          eventType: 'page',
          eventPayload: {
            name: 'LANDING',
            userId: currentUniqueId,
          },
        },
      })
      yield put({
        type: 'TRACK_ON_MIXPANEL',
        analytics: {
          eventType: 'identify',
          eventPayload: {
            userId: currentUniqueId,
            $email: userInfo.email,
            $name: userInfo.name,
            $phone: userInfo.phone,
            traits: {
              name: userInfo.name,
              email: userInfo.email,
              $phone: userInfo.phone,
              country: country.toUpperCase(),
            },
          },
        },
      })
    }
  }
}

export function* sendVerificationCode(api, action) {
  const response = yield call(
    api.sendVerificationCode,
    action.phoneCode + action.phoneNumber,
    action.organizationId,
  )
  switch (response.status) {
    case 200:
    case 201: {
      yield put(SessionActions.setSessionId(response.data.session_id))
      break
    }
    default:
      break
  }
}
export function* sendVerificationCodeEmail(api, action) {
  const response = yield call(
    api.sendVerificationCodeEmail,
    action.phoneCode + action.phoneNumber,
    action.email,
    action.organizationId,
  )
  switch (response.status) {
    case 200:
    case 201: {
      yield put(SessionActions.setSessionId(response.data.session_id))
      break
    }
    default:
      yield put(
        SessionActions.setLoginError(
          'El correo electrónico no corresponde al usuario creado con el telefono ingresado anteriormente',
        ),
      )
      yield put(
        ModalActions.setModalContent({
          show: true,
          title: 'Opss...',
          text:
            'El correo electrónico no corresponde al usuario creado con el telefono ingresado anteriormente',
          buttonText: 'Entiendo',
        }),
      )
      break
  }
}
export function* verifyCode(api, action) {
  const sessionId = yield select(state => state.session.sessionId)
  const phoneNumber = yield select(state => state.session.phoneNumber)
  const phoneCode = yield select(state => state.session.phoneCode)
  const { tokenAuth } = yield select(getSession)
  const response = yield call(
    api.verifyCode,
    tokenAuth,
    action.verificationCode,
    phoneCode + phoneNumber,
    sessionId,
  )
  switch (response.status) {
    case 200:
    case 201: {
      yield put(SessionActions.setToken(response.data.auth_token))
      if (response.data.user.email || action.register) {
        yield put(SessionActions.setUserData(response.data.user))
        yield put(SessionActions.setLoggedIn(true))
        yield put(ServiceActions.setCurrentStep(3))
      } else {
        yield put(
          SessionActions.setLoginError(
            'No existe una cuenta asociada a este número, para crear la cuenta debes solicitar tu primer servicio.',
          ),
        )
      }
      break
    }
    case 403:
    default:
      yield put(SessionActions.setLoggedIn(false))
      yield put(
        SessionActions.setCodeError(
          'El codigo ingresado es incorrecto',
        ),
      )
      break
  }
}
export function* updateUser(api, action) {
  const { tokenAuth } = yield select(getSession)
  const response = yield call(
    api.updateUser,
    action.userInfo,
    tokenAuth,
  )
  switch (response.status) {
    case 200:
    case 201: {
      yield put(SessionActions.setUserData(response.data))
      if (action.createService) {
        yield put(ServiceActions.createService())
      } else {
        yield put(
          ModalActions.setModalContent({
            show: true,
            title: 'Bien!',
            text:
              'Se ha guardado la informacion de perfil satisfactoriamente',
            buttonText: 'Entiendo',
          }),
        )
      }
      break
    }
    default:
      if (_.findKey(response.data.errors, 'email')) {
        yield put(
          ModalActions.setModalContent({
            show: true,
            title: 'Opss...',
            text:
              'El correo electrónico que ingresaste ya está en uso.',
            buttonText: 'Entiendo',
          }),
        )
      } else {
        yield put(
          ModalActions.setModalContent({
            show: true,
            title: 'Opss...',
            text:
              'Ha ocurrido un error. Por favor inténtalo nuevamente. Si el problema persiste, contáctanos haciendo click en el botón ubicado en la esquina inferior derecha de la pantalla.',
            buttonText: 'Entiendo',
          }),
        )
      }
  }
}
export function* logout() {
  const country = yield select(state => state.session.country)
  yield setToken({ country })
}
