import esCO from './esCO'
import esMX from './esMX'
import esCL from './esCL'
import esEC from './esEC'
import ptBR from './ptBR'
import enUS from './en'

const translates = {
  esCO,
  esMX,
  esCL,
  esEC,
  ptBR,
  enUS,
}

export default translates
