/* eslint-disable */

const ASSETS_URL = process.env.ASSETS_URL;
const GOOGLE_MAPS_KEY = process.env.GOOGLE_MAPS_KEY;
const REACT_APP_API = process.env.REACT_APP_API;
const REACT_APP_GEO_AWS_URL = process.env.REACT_APP_GEO_AWS_URL;
const ASSETS_FLAGS = process.env.ASSETS_FLAGS;
const REACT_APP_PUBNUB_SUBSCRIBE_KEY =
  process.env.REACT_APP_PUBNUB_SUBSCRIBE_KEY;
const AUTHORIZATION_TOKEN_COL = process.env.AUTHORIZATION_TOKEN_COL;
const AUTHORIZATION_TOKEN_MEX = process.env.AUTHORIZATION_TOKEN_MEX;
const AUTHORIZATION_TOKEN_CHL = process.env.AUTHORIZATION_TOKEN_CHL;
const AUTHORIZATION_TOKEN_ECU = process.env.AUTHORIZATION_TOKEN_ECU;
const AUTHORIZATION_TOKEN_BRA = process.env.AUTHORIZATION_TOKEN_BRA;
const REACT_APP_SEGMENT_TOKEN = process.env.REACT_APP_SEGMENT_TOKEN;
const IP_API_URL = process.env.IP_API_URL;
const API_STRAPI = process.env.API_STRAPI;

const ID_ENCARGOS = process.env.ID_ENCARGOS;
const ID_ACARREOS = process.env.ID_ACARREOS;
const ID_MUDANZAS = process.env.ID_MUDANZAS;
const ID_MUDANZAS_MEDIANAS = process.env.ID_MUDANZAS_MEDIANAS;
const ID_ENTREGAS_PEQUENAS = process.env.ID_ENTREGAS_PEQUENAS;
const ID_ENTREGAS_MEDIANAS = process.env.ID_ENTREGAS_MEDIANAS;
const ID_ENTREGAS_GRANDES = process.env.ID_ENTREGAS_GRANDES;
const ID_CARGA = process.env.ID_CARGA;
const ID_INDUSTRIAL = process.env.ID_INDUSTRIAL;
const HOST_TMS = process.env.HOST_TMS;
const ALLOWED_CITIES = ['bogota'];
/* eslint-enable */

const SERVICE_TYPES = [
  // {
  //   id: ID_ENCARGOS,
  //   image: 'https://s3.amazonaws.com/liftit-assets/v2/images/encargos.png',
  //   title: 'type-one.title',
  //   description: 'type-one.description',
  //   totalDescription: 'type-one.totalDescription',
  //   maxWeight: 20,
  //   maxVolume: 35 * 50 * 40,
  //   responseTime: 60,
  //   timeWindow: {
  //     start: 6,
  //     end: 20,
  //   },
  // },
  {
    id: ID_ACARREOS,
    image:
      'https://s3.amazonaws.com/liftit-assets/v2/images/acarreos.png',
    title: 'type-two.title',
    description: 'type-two.description',
    totalDescription: 'type-two.totalDescription',
    maxWeight: 1000,
    maxVolume: 150 * 150 * 200,
    responseTime: 200,
    timeWindow: {
      start: 6,
      end: 20,
    },
  },
  // {
  //   id: ID_MUDANZAS,
  //   image: 'https://s3.amazonaws.com/liftit-assets/v2/images/mudanzas.png',
  //   title: 'type-three.title',
  //   description: 'type-three.description',
  //   totalDescription: 'type-three.totalDescription',
  //   maxWeight: 4500,
  //   maxVolume: 150 * 175 * 200,
  //   responseTime: 120,
  //   timeWindow: {
  //     start: 6,
  //     end: 20,
  //   },
  // },
  // {
  //   id: ID_MUDANZAS_MEDIANAS,
  //   image:
  //     'https://s3.amazonaws.com/liftit-assets/v2/images/mudanzas_medianas.png',
  //   title: 'type-four.title',
  //   description: 'type-four.description',
  //   totalDescription: 'type-four.totalDescription',
  //   maxWeight: 2500,
  //   maxVolume: 170 * 185 * 230,
  //   responseTime: 120,
  //   timeWindow: {
  //     start: 6,
  //     end: 20,
  //   },
  // },
  // {
  //   id: ID_ENTREGAS_PEQUENAS,
  //   image:
  //     'https://s3.amazonaws.com/liftit-assets/v2/images/entregas_peque%C3%B1as.png',
  //   title: 'type-five.title',
  //   description: 'type-five.description',
  //   totalDescription: 'type-five.totalDescription',
  //   maxWeight: 50,
  //   maxVolume: 125 * 130 * 175,
  //   responseTime: 120,
  //   timeWindow: {
  //     start: 6,
  //     end: 20,
  //   },
  // },
  {
    id: ID_ENTREGAS_MEDIANAS,
    image:
      'https://s3.amazonaws.com/liftit-assets/v2/images/entregas_medianas.png',
    title: 'type-six.title',
    description: 'type-six.description',
    totalDescription: 'type-six.totalDescription',
    maxWeight: 150,
    maxVolume: 125 * 130 * 175,
    responseTime: 200,
    timeWindow: {
      start: 6,
      end: 20,
    },
  },

  // {
  //   id: ID_ENTREGAS_GRANDES,
  //   image:
  //     'https://s3.amazonaws.com/liftit-assets/v2/images/entregas_grandes.png',
  //   title: 'type-seven.title',
  //   description: 'type-seven.description',
  //   totalDescription: 'type-seven.totalDescription',
  //   maxWeight: 350,
  //   maxVolume: 125 * 130 * 175,
  //   responseTime: 120,
  //   timeWindow: {
  //     start: 6,
  //     end: 20,
  //   },
  // },
  // {
  //   id: ID_CARGA,
  //   image: 'https://s3.amazonaws.com/liftit-assets/v2/images/cargas.png',
  //   title: 'type-eight.title',
  //   description: 'type-eight.description',
  //   totalDescription: 'type-eight.totalDescription',
  //   maxWeight: 10000,
  //   maxVolume: 250 * 250 * 700,
  //   responseTime: 180,
  //   timeWindow: {
  //     start: 6,
  //     end: 20,
  //   },
  // },
  // {
  //   id: ID_INDUSTRIAL,
  //   image: 'https://s3.amazonaws.com/liftit-assets/v2/images/industrial.png',
  //   title: 'type-nine.title',
  //   description: 'type-nine.description',
  //   totalDescription: 'type-nine.totalDescription',
  //   maxWeight: 18000,
  //   maxVolume: 250 * 250 * 1200,
  //   responseTime: 180,
  //   timeWindow: {
  //     start: 6,
  //     end: 20,
  //   },
  // },
]

const STEPS_DATA = [
  {
    number: 1,
    title: 'steps_data.title1',
  },
  {
    number: 2,
    title: 'steps_data.title2',
  },
  {
    number: 3,
    title: 'steps_data.title3',
  },
  {
    number: 4,
    title: 'steps_data.title4',
  },
  {
    number: 5,
    title: 'steps_data.title5',
  },
]

const USER_INFO = {
  name: 'Liftit User',
  email: 'liftit.user@liftit.co',
  phone: '3117777777',
  phoneCode: '+57',
}

const LatLng = [
  {
    country: 'co',
    latLng: {
      lat: 4.570868,
      lng: -74.297333,
    },
  },
  {
    country: 'mx',
    latLng: {
      lat: 23.634501,
      lng: -102.552784,
    },
  },
  {
    country: 'cl',
    latLng: {
      lat: -35.675147,
      lng: -71.542969,
    },
  },
]

const ERRORS = {
  modalContent: {
    show: true,
    title: 'errors.title',
    text: 'errors.text',
    buttonText: 'errors.buttonText',
  },
  1: 'errors.error_1',
  1000: 'errors.error_1000',
  1001: 'errors.error_1001', // 'Service not found'
  1002: 'errors.error_1002', // 'Invalid number of tasks'
  1003: 'errors.error_1003', // 'Worker vehicle not found'
  1004: 'errors.error_1004', // 'Invalid params to create a service'
  1005: 'errors.error_1005', // "It's too late to schedule the service"
  1006: 'errors.error_1006', // 'Service type not found in organization'
  1011: 'errors.error_1011',
  1012: 'errors.error_1012',
  1013: 'errors.error_1013',
  1014: 'errors.error_1014',
  1015: 'errors.error_1015',
  1018: 'errors.error_1018',
  1020: 'errors.error_1020', // 'Worker Vehicle in invalid state',
  1021: 'errors.error_1021', // 'You can not access the worker vehicle location',
  1023: 'errors.error_1023', // 'Vehicle is busy',
  1024: 'errors.error_1024', // 'Worker is active in another vehicle',
  1025: 'errors.error_1025', // "User can't access to the service",
  1026: 'errors.error_1026',
  1027: 'errors.error_1027',
  1028: 'errors.error_1028', // 'The service was not scheduled',
  1029: 'errors.error_1029', // 'The service was already confirmed',
  1030: 'errors.error_1030', // "Invalid service type the vehicle can't support it",
  1031: 'errors.error_1031', // "Service can't be taken due to scheduling dates",
  1032: 'errors.error_1032', // 'The service is scheduled for later',
  1033: 'errors.error_1033', // 'Unable to cancel a service',
  1034: 'errors.error_1034',
  1035: 'errors.error_1035',
  1036: 'errors.error_1036',
  2000: 'errors.error_2000', // 'Organization not found',
  2001: 'errors.error_2001', // 'User not found using provided email',
  2002: 'errors.error_2002', // "This user doesn't have have requested role set",
  2003: 'errors.error_2003', // 'Error decrypting session_id',
  2004: 'errors.error_2004', // 'Invalid new user activation token',
  2005: 'errors.error_2005', // 'Requested user not found.',
  2006: 'errors.error_2006', // 'User has not been verified',
  2007: 'errors.error_2007', // 'INVALID provided password',
  2008: 'errors.error_2005', // 'User not found',
  2009: 'errors.error_2009', // 'Action not available for this type of user',
  2010: 'errors.error_2010', // 'Sesion not found using provided token',
  2011: 'errors.error_2011', // 'Provided reset_token is invalid',
  2012: 'errors.error_2012', // 'Provided reset_token is expired',
  2013: 'errors.error_2013', // "Provided email couldn't be found",
  2014: 'errors.error_2014', // "Session couldn't be found using provided auth_token",
  2015: 'errors.error_2015', // "User couldn't be found using provided auth_token",
  2016: 'errors.error_2016', // 'This sms belongs to a expired session',
  2017: 'errors.error_2017', // "Session couldn't be found using provided sms",
  2018: 'errors.error_2018',
  2019: 'errors.error_2019',
  2020: 'errors.error_2020', // 'Phone number should be a string.',
  3002: 'errors.error_3002', // 'Notification channel not found',
  4001: 'errors.error_4001', // 'Unable to get the worker location',
  4002: 'errors.error_4002', // 'Unable to get the worker location for the service',
  4003: 'errors.error_4003', // 'Unable to save the worker vehicle location',
  5001: 'errors.error_5001', // 'Organization webhook could not be stored'
}

export {
  ASSETS_URL,
  GOOGLE_MAPS_KEY,
  REACT_APP_API,
  REACT_APP_GEO_AWS_URL,
  REACT_APP_SEGMENT_TOKEN,
  ASSETS_FLAGS,
  REACT_APP_PUBNUB_SUBSCRIBE_KEY,
  STEPS_DATA,
  SERVICE_TYPES,
  USER_INFO,
  AUTHORIZATION_TOKEN_COL,
  AUTHORIZATION_TOKEN_MEX,
  AUTHORIZATION_TOKEN_CHL,
  AUTHORIZATION_TOKEN_ECU,
  AUTHORIZATION_TOKEN_BRA,
  LatLng,
  ERRORS,
  IP_API_URL,
  HOST_TMS,
  API_STRAPI,
  ALLOWED_CITIES,
}
