import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

const { Types, Creators } = createActions({
  openModal: null,
  closeModal: null,
  setModalContent: ['payload'],
  setRenderForm: ['render'],
})

export const ModalTypes = Types
export default Creators

export const INITIAL_STATE = Immutable({
  show: false,
  form: false,
  renderForm: true,
  title: '',
  text: '',
  buttonText: '',
  buttonTex2: '',
  link: null,
  close: null,
})

const openModal = state =>
  Immutable.merge(state, {
    show: true,
  })

const closeModal = state =>
  Immutable.merge(state, {
    ...INITIAL_STATE,
  })

const setModalContent = (state, { payload }) =>
  Immutable.merge(state, {
    ...payload,
  })

const setRenderForm = (state, { render }) =>
  Immutable.merge(state, {
    renderForm: render,
  })

export const reducer = createReducer(INITIAL_STATE, {
  [Types.OPEN_MODAL]: openModal,
  [Types.CLOSE_MODAL]: closeModal,
  [Types.SET_MODAL_CONTENT]: setModalContent,
  [Types.SET_RENDER_FORM]: setRenderForm,
})
