import { createReducer, createActions } from 'reduxsauce'

import { SERVICE_TYPES } from '../data/constants'

const { Types, Creators } = createActions({
  getService: null,
  setSelectService: ['service', 'avoidNextStep'],
  setServiceFromId: ['serviceTypeId', 't'],
  setOriginAddress: ['address'],
  setDestinationAddress: ['address'],
  searchGeoSuggest: ['address', 'source'],
  setGeoSuggestOrigin: ['suggest'],
  setGeoSuggestDestination: ['suggest'],
  resetSearchGeoSuggestOrigin: null,
  resetSearchGeoSuggestDestination: null,
  setCurrentOriginAddress: ['address'],
  setCurrentDestinationAddress: ['address'],
  setRoute: ['route'],
  cleanRoute: null,
  setDateAndHour: ['dateAndHour'],
  setUserInfo: ['userInfo'],
  setDetails: ['details'],
  setCurrentStep: ['currentStep'],
  createService: ['isFetching'],
  createServiceFinal: ['isFetching'],
  runService: null,
  setServiceStarted: ['runServiceInfo'],
  setService: ['serviceInfo'],
  setPhotos: ['photos'],
  setRequestType: ['requestType'],
  cleanState: null,
  cleanAggend: null,
  noFetching: ['isFetching'],
  notifyPubnub: ['notifyPubnub'],
  loading: ['isFetching'],
  searchByDirectAddress: ['address', 'source'],
  setPaid: ['paid', 'paymentId'],
  cleanCurrentService: null,
  setPaymentType: ['paymentType', 'avoidNextStep'],
  setValidService: ['validService'],
  sendConfirmationMessage: [
    'countryCode',
    'recipientPhone',
    'message',
    'confirmationCode',
  ],
  setInsuredAmount: ['insuredAmount'],
})

export const ServiceTypes = Types
export default Creators

export const INITIAL_STATE = {
  serviceTypes: SERVICE_TYPES,
  currentService: {},
  suggestOrigin: [],
  suggestDestination: [],
  currentOriginAddress: {
    city: '',
    department: '',
    formatted_address: '',
    lon: null,
    address: '',
    user_address: '',
    dep_state: '',
    lat: null,
    country: '',
    countryCode: '',
  },
  currentDestinationAddress: {
    city: '',
    department: '',
    formatted_address: '',
    lon: null,
    address: '',
    user_address: '',
    dep_state: '',
    lat: null,
    country: '',
    countryCode: '',
  },
  route: undefined,
  dateAndHour: '',
  details: '',
  currentStep: 0,
  userInfo: {},
  serviceInfo: {},
  photos: [],
  requestType: '',
  runServiceInfo: null,
  isFetching: false,
  paid: false,
  paymentId: '',
  paymentType: '',
  validService: false,
  confirmationCode: '',
  notifyPubnub: {},
  insuredAmount: 0,
}

const request = state => state

const setSelectService = (state, { service }) =>
  Object.assign({}, state, {
    currentService: service,
  })

const setOriginAddress = (state, { address }) =>
  Object.assign({}, state, {
    currentOriginAddress: { formatted_address: address },
  })

const setDestinationAddress = (state, { address }) =>
  Object.assign({}, state, {
    currentDestinationAddress: { formatted_address: address },
  })

const setGeoSuggestOrigin = (state, { suggest }) =>
  Object.assign({}, state, {
    suggestOrigin: suggest,
  })

const setGeoSuggestDestination = (state, { suggest }) =>
  Object.assign({}, state, {
    suggestDestination: suggest,
  })

const resetSearchGeoSuggestOrigin = state =>
  Object.assign({}, state, {
    suggestOrigin: [],
  })

const resetSearchGeoSuggestDestination = state =>
  Object.assign({}, state, {
    suggestDestination: [],
  })

const setCurrentOriginAddress = (state, { address }) =>
  Object.assign({}, state, {
    currentOriginAddress: address,
  })

const setCurrentDestinationAddress = (state, { address }) =>
  Object.assign({}, state, {
    currentDestinationAddress: address,
  })

const setRoute = (state, { route }) =>
  Object.assign({}, state, {
    route,
  })

const cleanRoute = state =>
  Object.assign({}, state, {
    route: undefined,
  })

const setUserInfo = (state, { userInfo }) =>
  Object.assign({}, state, {
    userInfo,
  })

const setDateAndHour = (state, { dateAndHour }) =>
  Object.assign({}, state, {
    dateAndHour,
  })

const setDetails = (state, { details }) =>
  Object.assign({}, state, {
    details,
  })

const setCurrentStep = (state, { currentStep }) =>
  Object.assign({}, state, {
    currentStep,
  })

const createService = state =>
  Object.assign({}, state, {
    isFetching: true,
  })

const createServiceFinal = state =>
  Object.assign({}, state, {
    isFetching: true,
  })

const fetching = state =>
  Object.assign({}, state, {
    isFetching: true,
  })

const noFetching = state =>
  Object.assign({}, state, {
    isFetching: false,
  })

const loading = state =>
  Object.assign({}, state, {
    isFetching: true,
  })

const sendConfirmationMessage = (state, { confirmationCode }) =>
  Object.assign({}, state, {
    confirmationCode,
  })

const setNotifyPubnub = (state, { notifyPubnub }) =>
  Object.assign({}, state, {
    notifyPubnub,
  })

const setService = (state, { serviceInfo }) =>
  Object.assign({}, state, {
    serviceInfo,
    isFetching: false,
  })

const setPhotos = (state, { photos }) =>
  Object.assign({}, state, {
    photos,
  })

const setRequestType = (state, { requestType }) =>
  Object.assign({}, state, {
    requestType,
  })

const setServiceStarted = (state, { runServiceInfo }) =>
  Object.assign({}, state, {
    runServiceInfo,
    isFetching: false,
  })

const setPaid = (state, { paid, paymentId }) =>
  Object.assign({}, state, {
    paid,
    paymentId,
  })

const setPaymentType = (state, { paymentType }) =>
  Object.assign({}, state, {
    paymentType,
  })

const cleanState = state =>
  Object.assign({}, state, {
    ...INITIAL_STATE,
    userInfo: state.userInfo,
  })

const cleanAggend = state => {
  const { currentService } = state
  return Object.assign({}, state, {
    ...INITIAL_STATE,
    currentService,
    currentStep: -1,
    userInfo: state.userInfo,
  })
}

const cleanCurrentService = state =>
  Object.assign({}, state, {
    currentService: {},
  })

const setValidService = (state, { validService }) =>
  Object.assign({}, state, {
    validService,
  })

const setInsuredAmount = (state, { insuredAmount }) =>
  Object.assign({}, state, {
    insuredAmount,
  })

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_SERVICE]: request,
  [Types.SET_SELECT_SERVICE]: setSelectService,
  [Types.SET_ORIGIN_ADDRESS]: setOriginAddress,
  [Types.SET_DESTINATION_ADDRESS]: setDestinationAddress,
  [Types.SEARCH_GEO_SUGGEST]: request,
  [Types.SET_GEO_SUGGEST_ORIGIN]: setGeoSuggestOrigin,
  [Types.SET_GEO_SUGGEST_DESTINATION]: setGeoSuggestDestination,
  [Types.RESET_SEARCH_GEO_SUGGEST_ORIGIN]: resetSearchGeoSuggestOrigin,
  [Types.RESET_SEARCH_GEO_SUGGEST_DESTINATION]: resetSearchGeoSuggestDestination,
  [Types.SET_CURRENT_ORIGIN_ADDRESS]: setCurrentOriginAddress,
  [Types.SET_CURRENT_DESTINATION_ADDRESS]: setCurrentDestinationAddress,
  [Types.SET_ROUTE]: setRoute,
  [Types.CLEAN_ROUTE]: cleanRoute,
  [Types.SET_USER_INFO]: setUserInfo,
  [Types.SET_DATE_AND_HOUR]: setDateAndHour,
  [Types.SET_DETAILS]: setDetails,
  [Types.SET_CURRENT_STEP]: setCurrentStep,
  [Types.CREATE_SERVICE]: createService,
  [Types.CREATE_SERVICE_FINAL]: createServiceFinal,
  [Types.RUN_SERVICE]: fetching,
  [Types.SET_SERVICE_STARTED]: setServiceStarted,
  [Types.SET_SERVICE]: setService,
  [Types.SET_PHOTOS]: setPhotos,
  [Types.SET_REQUEST_TYPE]: setRequestType,
  [Types.CLEAN_STATE]: cleanState,
  [Types.CLEAN_AGGEND]: cleanAggend,
  [Types.NO_FETCHING]: noFetching,
  [Types.LOADING]: loading,
  [Types.SEARCH_BY_DIRECT_ADDRESS]: request,
  [Types.SET_PAID]: setPaid,
  [Types.SET_SERVICE_FROM_ID]: request,
  [Types.CLEAN_CURRENT_SERVICE]: cleanCurrentService,
  [Types.SET_PAYMENT_TYPE]: setPaymentType,
  [Types.SET_VALID_SERVICE]: setValidService,
  [Types.SEND_CONFIRMATION_MESSAGE]: sendConfirmationMessage,
  [Types.NOTIFY_PUBNUB]: setNotifyPubnub,
  [Types.SET_INSURED_AMOUNT]: setInsuredAmount,
})
