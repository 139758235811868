import { all, select, call, put, fork, take } from 'redux-saga/effects'
import { eventChannel } from 'redux-saga'
import PubNubReact from 'pubnub-react'
import Moment from 'moment'
import API from '../services/api'
import {
  TrackingActions,
  realtimetrakinit,
  trackingInit,
} from '../reducers/tracking'

import {
  advanceTime,
  formatDate,
  convertLatLng,
  getRoutes,
} from '../utils/trackingUtils'
import { turnCar } from '../utils/MathLineal'
import { REACT_APP_PUBNUB_SUBSCRIBE_KEY } from '../data/constants'

export const api = API.create()
export const pubnub = new PubNubReact({
  subscribeKey: REACT_APP_PUBNUB_SUBSCRIBE_KEY,
})

export function* setCurrentUuid(action) {
  const beforeUuid = yield select(state => state.tracking.service)
  const uuid = action.uuid === 'reload' ? beforeUuid.uuid : action.uuid
  const response = yield call(api.getServiceInfo, uuid)
  switch (response.status) {
    case 200:
      if (
        response.data.status === 'ok' &&
        response.data.data !== 'zero_results'
      ) {
        yield put(TrackingActions.setResponse(response.data.data))
      } else {
        const serviceNotFound = {
          status: 404,
          worker: null,
          uuid,
        }

        yield all([
          put(TrackingActions.setServiceInfo(serviceNotFound)),
          put(
            TrackingActions.setClearState(
              trackingInit,
              [],
              realtimetrakinit,
              0,
              { uuid: '/' },
              '',
            ),
          ),
        ])
      }
      break
    default:
      if (response.problem !== 'TIMEOUT_ERROR') {
        const errorServer = {
          status: 500,
          worker: null,
          uuid,
        }
        yield all([
          put(TrackingActions.setServiceInfo(errorServer)),
          put(
            TrackingActions.setClearState(
              trackingInit,
              [],
              realtimetrakinit,
              0,
              { uuid: '/' },
              '',
            ),
          ),
        ])
      }
  }
}

export function* subscribeDriver(workerVehicleId, serviceId) {
  const workerChannel = `vehicle_track_${workerVehicleId}`
  const serviceChannel = `service_${serviceId}_status`

  const subscribeVehicle = () =>
    eventChannel(emmit => {
      const listener = {
        message: m =>
          m.channel.includes('service_')
            ? emmit({
                type: 'SET_CHANGE_STATUS_TASK',
                status: { message: m.message },
              })
            : emmit(
                TrackingActions.setRealTimeTracking(
                  m.message.properties,
                ),
              ),
      }

      pubnub.addListener(listener)
      const channels = [workerChannel, serviceChannel]

      pubnub.subscribe({
        channels,
      })

      return () => pubnub.removeListener(listener)
    })

  let channel
  try {
    channel = yield call(subscribeVehicle)

    while (true) {
      const action = yield take(channel)
      yield put(action)
    }
  } finally {
    yield call(channel.close)
  }
}

export function* setServiceInfo(action) {
  const { response } = action
  const { service } = response
  const tasksFormated = []
  service.tasks.map(taskO => {
    const task = taskO
    if (task.status >= 1 && task.status < 4) {
      const initialTime = Moment(task.going_time)
      const currentTime = Moment()
      const finalTime = Moment(task.eta_time)
      task.advance = `${advanceTime(
        initialTime,
        currentTime,
        finalTime,
      )}%`
      task.eta_time = formatDate(task.eta_time)
    }
    task.warehouse = formatDate(task.warehouse)
    task.going_time = formatDate(task.going_time)
    task.delivered_time = formatDate(task.delivered_time)
    task.updated_at = formatDate(task.updated_at)
    task.going_time = task.updated_at

    if (task.status === 101) {
      task.status = 6
    }
    tasksFormated.push(task)
    return 1
  })

  service.tasks = tasksFormated

  service.updated_at = formatDate(service.updated_at)

  service.start_point = {
    lat: service.tasks[0].lat,
    lng: service.tasks[0].lon,
  }
  service.end_point = {
    lat: service.tasks[1].lat,
    lng: service.tasks[1].lon,
  }

  const tracking = {
    defaultcenter: service.start_point,
    start_point: service.start_point,
    end_point: service.end_point,
    validService: true,
  }

  let points = []
  if (response.tracking) {
    points = convertLatLng(response.tracking.coordinates)
    tracking.defaultcenter = points[parseInt(points.length - 1, 10)]

    let request = []
    if (points.length >= 5) {
      request = yield call(getRoutes, points)
    } else {
      yield put(TrackingActions.setCoordinates(points))
    }

    if (request.status === 'OK') {
      const pointsCoordinates = []
      request.routes[0].overview_path.map(point =>
        pointsCoordinates.push({ lat: point.lat(), lng: point.lng() }),
      )

      yield put(TrackingActions.setCoordinates(pointsCoordinates))
      tracking.defaultcenter =
        pointsCoordinates[pointsCoordinates.length - 1]
    }
    yield put(TrackingActions.setTracking(tracking))
  } else {
    yield all([
      put(TrackingActions.setCoordinates([])),
      put(TrackingActions.setTracking(tracking)),
    ])
  }
  yield fork(subscribeDriver, service.worker_vehicle_id, service.id)
  yield put(TrackingActions.setServiceInfo(service))
}

export function* setDireccions(action) {
  if (action.coordinates !== null && action.coordinates.length >= 3) {
    const endPoint = action.coordinates[action.coordinates.length - 1]
    const basePoint = action.coordinates[action.coordinates.length - 2]
    let rotateCar = turnCar(
      [basePoint.lng, basePoint.lat],
      [endPoint.lng, endPoint.lat],
    )

    if (Number.isNaN(rotateCar)) {
      let v1 = [basePoint.lng, basePoint.lat]
      let v2 = [endPoint.lng, endPoint.lat]
      if (typeof basePoint.lng === 'function')
        v1 = [basePoint.lng(), basePoint.lat()]
      if (typeof endPoint.lng === 'function')
        v2 = [endPoint.lng(), endPoint.lat()]
      rotateCar = turnCar(v1, v2)
    }
    yield put(TrackingActions.setDegressCar(rotateCar))
  }
}

export function* setChangeStatus(action) {
  const { task, service } = action.status.message
  const statusUnsuscribe = ['4', '5', '101']

  yield fork(setCurrentUuid, { uuid: 'reload' })

  if (
    service &&
    task &&
    task.order === 2 &&
    statusUnsuscribe.includes(task.status.toString())
  ) {
    const serviceChannel = `service_${service.id}_status`
    const workerChannel = `vehicle_track_${service.worker_vehicle_id}`
    pubnub.unsubscribe({
      channels: [serviceChannel, workerChannel],
    })
  }
}

export function* setRealTimeTracking(action) {
  const trackingPoints = yield select(
    state => state.tracking.coordinates,
  )
  const newPoint = {
    lat: action.realtimetrack.lat,
    lng: action.realtimetrack.lon,
  }
  yield put(
    TrackingActions.setCoordinates([...trackingPoints, newPoint]),
  )
}
