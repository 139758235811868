import apisauce from 'apisauce'
import { REACT_APP_API } from '../data/constants'

const create = (baseURL = REACT_APP_API) => {
  // timeout: 2000
  const api = apisauce.create({
    baseURL,
    timeout: 30000,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'client-type': 'mobile',
      'device-id': '123456',
      'user-agent': 'express',
    },
  })

  const getServiceInfo = query =>
    api.get('/show_service', { uuid: query })

  const createService = (data, authToken) =>
    api.post('/service', data, {
      headers: {
        authorization: authToken,
      },
    })

  const runService = (serviceId, authToken) =>
    api.put(
      `/service/${serviceId}/run`,
      {},
      {
        headers: {
          authorization: authToken,
        },
      },
    )

  const sendVerificationCode = (phoneNumber, organizationId) =>
    api.post(
      'auth/verify_user_existence',
      {
        phone_number: phoneNumber,
        organization_id: organizationId,
        is_btc: 'true',
      },
      { headers: {} },
    )

  const sendVerificationCodeEmail = (
    phoneNumber,
    email,
    organizationId,
  ) =>
    api.post(
      'auth/generate_code_email',
      {
        phone_number: phoneNumber,
        email,
        organization_id: organizationId,
      },
      { headers: {} },
    )

  const verifyCode = (authToken, sms, phoneNumber, sessionId) =>
    api.post(
      'auth/verify_sms',
      { sms, phone_number: phoneNumber, session_id: sessionId },
      { headers: { authorization: authToken } },
    )

  const updateUser = (user, authToken) =>
    api.put(
      `/user/${user.id}`,
      { user },
      {
        headers: {
          authorization: authToken,
        },
      },
    )

  const sendSMS = (recipient, authToken) =>
    api.post('notifications', recipient, {
      headers: {
        authorization: authToken,
      },
    })

  const getHistory = authToken =>
    api.get(
      'service?limit=10',
      {},
      { headers: { authorization: authToken } },
    )

  return {
    getServiceInfo,
    createService,
    runService,
    sendSMS,
    getHistory,
    sendVerificationCode,
    sendVerificationCodeEmail,
    verifyCode,
    updateUser,
  }
}

export default {
  create,
}
