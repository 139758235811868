const path = 4

export const dot = (v1, v2) => {
  if (v1.length !== v2.length) {
    return ['error', 'matrices desiguales']
  }
  const result = v1
    .map((obj, index) => obj * v2[index])
    .reduce((a, b) => a + b)
  return result
}

const norm = vector => {
  const sqrt = Math.sqrt(
    vector.map(obj => obj ** 2).reduce((a, b) => a + b),
  )
  return sqrt ** 2
}

export const hypot = (v1, v2) => {
  const vector = v2
    .map((obj, index) => (obj - v1[index]) ** 2)
    .reduce((a, b) => a + b)
  return Math.sqrt(vector)
}

export const normAarrowB = vector =>
  Math.abs(vector.reduce((a, b) => a - b))

export const calcAngle = (v1, v2) => {
  const opposite = normAarrowB([v2[1], v1[1]])
  const hypotenuse = hypot(v1, v2)
  const degrees = Math.asin(opposite / hypotenuse) * 57.2956
  return degrees
}

export const turnCar = (v1, v2) => {
  if (v2[0] === v1[0] || v2[1] === v1[0]) return 0
  const x = v2[0] > v1[0]
  const y = v2[1] > v1[1]
  if (x && y) return calcAngle(v1, v2) * -1

  if (!x && y) return calcAngle(v1, v2) + 180

  if (!x && !y) return 540 - calcAngle(v1, v2)

  if (x && !y) return calcAngle(v1, v2)

  return 0
}

export const proyuV = (U, V) =>
  V.map(obj => (dot(U, V) / norm(V)) * obj)

export default path
