import { createReducer, createActions } from 'reduxsauce'
import { Map } from 'immutable'
/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  initDrivers: null,
  mapUpdateRegion: ['bounds', 'center', 'pitch', 'zoom'],
  updateElements: ['elements'],
  updateMarkers: ['markers'],
  updateDriver: ['driver'],
  updateRoutes: ['routes'],
  updateBounds: ['bounds'],
  cleanMapDirections: null,
  cleanMapMarkers: null,
  cleanDriver: null,
  centerMap: ['center'],
  setBrowserCoords: ['browser_coords'],
  updateUserLocation: ['userLocation'],
  setRouteSource: ['routeSource'],
})

export const MapTypes = Types
export default Creators

/* ------------- Initial State ------------ - */
export const ROUTE_SOURCE_INITIAL = {
  type: 'geojson',
  data: {
    type: 'FeatureCollection',
    features: [],
  },
}

export const INITIAL_STATE = Map({
  center: [-74.07783798236052, 4.66448061003635],
  pitch: 0,
  zoom: 14,
  markers: [],
  directions: [],
  driver: [],
  elements: [],
  bounds: {
    top: 4.737188671004361,
    bottom: 4.521840035090008,
    left: -73.96129428745712,
    right: -74.35199131112613,
  },
  userLocation: {
    countryCode: 'co',
  },
  routeSource: ROUTE_SOURCE_INITIAL,
})

/* ------------- Reducers ------------- */
const nothing = state => state

const updateRegion = (state, { bounds, center, pitch, zoom }) =>
  state.mergeDeep(
    Map({
      bounds,
      center,
      pitch,
      zoom,
    }),
  )

const updateRoutes = (state, { routes }) =>
  state.mergeDeep(
    Map({
      directions: routes,
    }),
  )

const updateMarkers = (state, { markers }) =>
  state.mergeDeep(
    Map({
      markers,
    }),
  )

const cleanDirections = state =>
  state.mergeDeep(
    Map({
      directions: [],
    }),
  )

const cleanMarkers = state =>
  state.mergeDeep(
    Map({
      markers: [],
    }),
  )

const updateBounds = (state, { bounds }) =>
  state.mergeDeep(
    Map({
      bounds,
    }),
  )

const updateDriver = (state, { driver }) =>
  state.mergeDeep(Map({ driver }))

const updateElements = (state, { elements }) =>
  state.mergeDeep(Map({ elements }))

const centerMap = (state, { center }) =>
  state.mergeDeep(Map({ center }))

const updateUserLocation = (state, { userLocation }) =>
  state.mergeDeep(Map({ userLocation }))

const setRouteSource = (state, { routeSource }) =>
  state.mergeDeep(Map({ routeSource }))

const cleanDriver = state =>
  state.mergeDeep(
    Map({
      driver: [],
    }),
  )

export const reducer = createReducer(INITIAL_STATE, {
  [Types.MAP_UPDATE_REGION]: updateRegion,
  [Types.UPDATE_ROUTES]: updateRoutes,
  [Types.UPDATE_MARKERS]: updateMarkers,
  [Types.UPDATE_BOUNDS]: updateBounds,
  [Types.CLEAN_MAP_DIRECTIONS]: cleanDirections,
  [Types.CLEAN_MAP_MARKERS]: cleanMarkers,
  [Types.UPDATE_DRIVER]: updateDriver,
  [Types.UPDATE_ELEMENTS]: updateElements,
  [Types.CLEAN_DRIVER]: cleanDriver,
  [Types.CENTER_MAP]: centerMap,
  [Types.SET_BROWSER_COORDS]: nothing,
  [Types.UPDATE_USER_LOCATION]: updateUserLocation,
  [Types.SET_ROUTE_SOURCE]: setRouteSource,
})
