import apisauce from 'apisauce'

import { IP_API_URL } from '../data/constants'

const create = (baseURL = IP_API_URL) => {
  const api = apisauce.create({
    baseURL,
    timeout: 10000,
    headers: {},
  })

  const getLocation = () => api.get(`json`)

  return { getLocation }
}

// let's return back our create method as the default.
export default {
  create,
}
