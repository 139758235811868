import React from 'react'
import PropTypes from 'prop-types'

class Redirect extends React.Component {
  componentDidMount() {
    window.location.href = this.props.to
  }

  render() {
    return null
  }
}

Redirect.propTypes = {
  to: PropTypes.string,
}

export default Redirect
