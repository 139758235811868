import { put, select } from 'redux-saga/effects'

const getCurrentService = state => state.service.currentService

export default function* onLocationScreen() {
  const currentService = yield select(getCurrentService)
  yield put({
    type: 'TRACK_ON_MIXPANEL',
    analytics: {
      eventType: 'page',
      eventPayload: {
        name: 'ADDRESSES',
      },
    },
  })
  yield put({
    type: 'TRACK_ON_MIXPANEL',
    analytics: {
      eventType: 'track',
      eventPayload: {
        event: 'SERVICE_TYPE_SELECTED',
        properties: {
          service_type_id: currentService.id,
          service_type_image: currentService.image,
          service_type_name: currentService.title,
        },
      },
    },
  })
}
