import apisauce from 'apisauce'

import { API_STRAPI } from '../data/constants'

const Banner = (baseURL = API_STRAPI) => {
  const api = apisauce.create({
    baseURL,
    timeout: 10000,
    headers: {},
  })

  const getBanner = () => api.get(`/banners`)

  return { getBanner }
}

const Vistas = (baseURL = API_STRAPI) => {
  const api = apisauce.create({
    baseURL,
    timeout: 10000,
    headers: {},
  })

  const getVistas = () => api.get(`/vistas`)

  return { getVistas }
}

const Boxfoalts = (baseURL = API_STRAPI) => {
  const api = apisauce.create({
    baseURL,
    timeout: 10000,
    headers: {},
  })

  const getBoxfoalts = () => api.get(`/cajaflotantes`)

  return { getBoxfoalts }
}

const OurClients = (baseURL = API_STRAPI) => {
  const api = apisauce.create({
    baseURL,
    timeout: 10000,
    headers: {},
  })

  const getOurClients = () => api.get(`/nuestrosclientes`)

  return { getOurClients }
}

const Blog = (baseURL = API_STRAPI) => {
  const api = apisauce.create({
    baseURL,
    timeout: 10000,
    headers: {},
  })

  const getBlog = () => api.get(`/blogs`)

  return { getBlog }
}

const TypeText = (baseURL = API_STRAPI) => {
  const api = apisauce.create({
    baseURL,
    timeout: 10000,
    headers: {},
  })

  const getTypeText = () => api.get(`/conttextpimaries`)

  return { getTypeText }
}

const MyTexts = (baseURL = API_STRAPI) => {
  const api = apisauce.create({
    baseURL,
    timeout: 10000,
    headers: {},
  })

  const getMyTexts = () => api.get(`/mytexts`)

  return { getMyTexts }
}

const Menu = (baseURL = API_STRAPI) => {
  const api = apisauce.create({
    baseURL,
    timeout: 10000,
    headers: {},
  })

  const getMenu = () => api.get(`/menus`)

  return { getMenu }
}
const Fotter = (baseURL = API_STRAPI) => {
  const api = apisauce.create({
    baseURL,
    timeout: 10000,
    headers: {},
  })

  const getFotter = () => api.get(`/footers`)

  return { getFotter }
}

const Links = (baseURL = API_STRAPI) => {
  const api = apisauce.create({
    baseURL,
    timeout: 10000,
    headers: {},
  })

  const getLinks = () => api.get(`/links`)

  return { getLinks }
}

const BotonesMenu = (baseURL = API_STRAPI) => {
  const api = apisauce.create({
    baseURL,
    timeout: 10000,
    headers: {},
  })

  const getBotonesMenu = () => api.get(`/menuprincipals`)

  return { getBotonesMenu }
}

const ContSliderLogistica = (baseURL = API_STRAPI) => {
  const api = apisauce.create({
    baseURL,
    timeout: 10000,
    headers: {},
  })

  const getContSliderLogistica = () =>
    api.get(`/contenedorsliderlogisticas`)

  return { getContSliderLogistica }
}

const Slider = (baseURL = API_STRAPI) => {
  const api = apisauce.create({
    baseURL,
    timeout: 10000,
    headers: {},
  })

  const getSlider = () => api.get(`/sliders`)

  return { getSlider }
}

const ContSliderHitos = (baseURL = API_STRAPI) => {
  const api = apisauce.create({
    baseURL,
    timeout: 10000,
    headers: {},
  })

  const getContSliderHitos = () => api.get(`/contenedorsliderhistorias`)

  return { getContSliderHitos }
}

const ContSliderBlog = (baseURL = API_STRAPI) => {
  const api = apisauce.create({
    baseURL,
    timeout: 10000,
    headers: {},
  })

  const getContSliderBlog = () => api.get(`/contsliderblogs`)

  return { getContSliderBlog }
}

const ContArticuloTwoType = (baseURL = API_STRAPI) => {
  const api = apisauce.create({
    baseURL,
    timeout: 10000,
    headers: {},
  })

  const getContArticuloTwoType = () => api.get(`/contarticulotwotypes`)

  return { getContArticuloTwoType }
}

const ConfFloatBlog = (baseURL = API_STRAPI) => {
  const api = apisauce.create({
    baseURL,
    timeout: 10000,
    headers: {},
  })

  const getConfFloatBlog = () => api.get(`/conffloatblogs`)

  return { getConfFloatBlog }
}

const ContTextTwoImg = (baseURL = API_STRAPI) => {
  const api = apisauce.create({
    baseURL,
    timeout: 10000,
    headers: {},
  })

  const getContTextTwoImg = () => api.get(`/conttexttwoimgs`)

  return { getContTextTwoImg }
}

const ContVideo = (baseURL = API_STRAPI) => {
  const api = apisauce.create({
    baseURL,
    timeout: 10000,
    headers: {},
  })

  const getContVideo = () => api.get(`/contvideos`)

  return { getContVideo }
}

const ContClientes = (baseURL = API_STRAPI) => {
  const api = apisauce.create({
    baseURL,
    timeout: 10000,
    headers: {},
  })

  const getContClientes = () => api.get(`/contclientes`)

  return { getContClientes }
}

const ContArticulosh = (baseURL = API_STRAPI) => {
  const api = apisauce.create({
    baseURL,
    timeout: 10000,
    headers: {},
  })

  const getContArticulosh = () => api.get(`/contarticuloshes`)

  return { getContArticulosh }
}
const ContArticuloColum = (baseURL = API_STRAPI) => {
  const api = apisauce.create({
    baseURL,
    timeout: 10000,
    headers: {},
  })

  const getContArticuloColum = () => api.get(`/contarticulocolums`)

  return { getContArticuloColum }
}
const ContArticuloWhite = (baseURL = API_STRAPI) => {
  const api = apisauce.create({
    baseURL,
    timeout: 10000,
    headers: {},
  })

  const getContArticuloWhite = () => api.get(`/contarticulowhites`)

  return { getContArticuloWhite }
}

// let's return back our create method as the default.
export default {
  Banner,
  Vistas,
  Boxfoalts,
  Blog,
  TypeText,
  MyTexts,
  OurClients,
  Menu,
  Fotter,
  Links,
  BotonesMenu,
  ContSliderLogistica,
  Slider,
  ContSliderHitos,
  ContSliderBlog,
  ContArticuloTwoType,
  ContTextTwoImg,
  ContVideo,
  ContArticuloColum,
  ContArticuloWhite,
  ContArticulosh,
  ContClientes,
  ConfFloatBlog,
}
