import home from './home.json'
import components from './components.json'
import location from './location.json'
import express from './express.json'
import success from './success.json'
import book from './book.json'
import cities from './cities.json'
import profile from './profile.json'
import login from './login.json'
import myservices from './myservices.json'
import error from './error.json'
import liftit from './liftit.json'
import landing from './landing.json'

const esCL = {
  home,
  components,
  location,
  express,
  success,
  book,
  cities,
  profile,
  login,
  myservices,
  error,
  liftit,
  landing,
}

export default esCL
