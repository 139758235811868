import { createActions, createReducer } from 'reduxsauce'

/* Types and Actions Creators  */
const { Types, Creators } = createActions({
  changeLanguage: ['payload'],
})

export const AppTypes = Types
export default Creators

/* Initial state */
export const INITIAL_STATE = {
  languageCode: '',
}

/* Reducers */
const changeLanguage = (state, { payload }) => ({
  ...state,
  ...payload,
})

/* Hookup Reducers to Types */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.CHANGE_LANGUAGE]: changeLanguage,
})
