import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

const { Types, Creators } = createActions({
  setCurrentUuid: ['uuid'],
  setResponse: ['response'],
  setPubnubSuscribe: ['pubnubSuscribe'],
  setCoordinates: ['coordinates'],
  setDegressCar: ['degresscar'],
  setTracking: ['tracking'],
  setServiceInfo: ['service'],
  setRealTimeTracking: ['realtimetrack'],
  setClearState: [
    'tracking',
    'coordinates',
    'realtimetrack',
    'degresscar',
    'response',
    'pubnubSuscribe',
  ],
  setFetching: ['fetching'],
  setBounds: ['bounds'],
})

export const TrackingTypes = Types
export const TrackingActions = Creators

export const InitialTask = {
  warehouse: {
    date: 'N/A',
    hour: '1:00 pm',
  },
  uuid: 'N/A',
  status: -1,
  signature: null,
  service: {
    worker: {
      name: 'N/A',
    },
  },
  score: 'N/A',
  plate: 'N/A',
  name: 'N/A',
  going_time: 'N/A',
  eta_time: 'N/A',
  delivered_time: 'N/A',
  advance: '0%',
}

export const serviceInit = {
  uuid: 'N/A',
  plate: 'N/A',
  tasks: [
    {
      InitialTask,
    },
  ],
}

export const realtimetrakinit = {
  worker_vehicle_id: 1,
  user_id: 1,
  task_id: 1,
  service_id: 1,
  lon: -74.1267341,
  lat: 4.6703241,
  inserted_at: '2018-08-28T19:26:28.475170',
}

export const trackingInit = {
  start_point: { lng: -74.05248641967773, lat: 4.680454682128591 },
  defaultcenter: { lat: 4.707152, lng: -74.045674 },
  end_point: { lng: -74.06810760498047, lat: 4.677118439957038 },
}

export const INITIAL_STATE = Immutable({
  uuid: '',
  service: serviceInit,
  tracking: trackingInit,
  coordinates: [],
  realtimetrack: realtimetrakinit,
  degresscar: 0,
  response: { uuid: '/' },
  pubnubSuscribe: '',
  bounds: false,
})

const setCurrentUuid = (state, { uuid }) =>
  Immutable.merge(state, {
    uuid,
  })

const setResponse = (state, { response }) =>
  Immutable.merge(state, {
    response,
  })

const setPubnubSuscribe = (state, { pubnubSuscribe }) =>
  Immutable.merge(state, {
    pubnubSuscribe,
  })

const setCoordinates = (state, { coordinates }) =>
  Immutable.merge(state, {
    coordinates,
  })

const setDegressCar = (state, { degresscar }) =>
  Immutable.merge(state, {
    degresscar,
  })

const setTracking = (state, { tracking }) =>
  Immutable.merge(state, {
    tracking,
  })

const setServiceInfo = (state, { service }) =>
  Immutable.merge(state, {
    service,
  })

const setRealTimeTracking = (state, { realtimetrack }) =>
  Immutable.merge(state, {
    realtimetrack,
  })

const setFetching = (state, { fetching }) =>
  Immutable.merge(state, {
    fetching,
  })

const setBounds = (state, { bounds }) =>
  Immutable.merge(state, {
    bounds,
  })

const setClearState = (
  state,
  {
    tracking,
    coordinates,
    realtimetrack,
    degresscar,
    response,
    pubnubSuscribe,
  },
) =>
  Immutable.merge(state, {
    tracking,
    coordinates,
    realtimetrack,
    degresscar,
    response,
    pubnubSuscribe,
  })

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_CURRENT_UUID]: setCurrentUuid,
  [Types.SET_RESPONSE]: setResponse,
  [Types.SET_PUBNUB_SUSCRIBE]: setPubnubSuscribe,
  [Types.SET_COORDINATES]: setCoordinates,
  [Types.SET_DEGRESS_CAR]: setDegressCar,
  [Types.SET_TRACKING]: setTracking,
  [Types.SET_SERVICE_INFO]: setServiceInfo,
  [Types.SET_REAL_TIME_TRACKING]: setRealTimeTracking,
  [Types.SET_CLEAR_STATE]: setClearState,
  [Types.SET_FETCHING]: setFetching,
  [Types.SET_BOUNDS]: setBounds,
})
