import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

const { Types, Creators } = createActions({
  getSession: null,
  setLocation: null,
  loaderVistas: null,
  loaderBanner: null,
  loaderBoxfoalt: null,
  loaderOurClients: null,
  loaderMenu: null,
  loaderFotter: null,
  loaderBlog: null,
  loaderLinks: null,
  loaderBotonesMenu: null,
  loaderContSliderLogistica: null,
  loaderContSliderHitos: null,
  loaderContSliderBlog: null,
  loaderContArticuloTwoType: null,
  loaderConfFloatBlog: null,
  loaderContArticuloColum: null,
  loaderContTextTwoImg: null,
  loaderContArticuloWhite: null,
  loaderContVideo: null,
  loaderContClientes: null,
  loaderContArticulosh: null,
  loaderTypeText: null,
  loaderSliber: null,
  loaderMyTexts: null,
  setLoaderVistas: ['vistas'],
  setLoaderBanner: ['banner'],
  setLoaderBoxfoalts: ['boxfoalts'],
  setLoaderOurClients: ['ourClients'],
  setLoaderMenu: ['menu'],
  setLoaderFotter: ['fotter'],
  setMenuDonw: ['menuDonw'],
  setLoaderLinks: ['links'],
  setLoaderBotonesMenu: ['botonesMenu'],
  setContSliderLogistica: ['contSliderLogistica'],
  setContSliderHitos: ['contSliderHitos'],
  setContSliderBlog: ['contSliderBlog'],
  setContArticuloTwoType: ['contArticuloTwoType'],
  setConfFloatBlog: ['confFloatBlog'],
  setContArticuloColum: ['contArticuloColum'],
  setContTextTwoImg: ['contTextTwoImg'],
  setContArticuloWhite: ['contArticuloWhite'],
  setContVideo: ['contVideo'],
  setContClientes: ['contClientes'],
  setContArticulosh: ['contArticulosh'],
  setLoaderBlog: ['blog'],
  setLoaderTypeText: ['typeText'],
  setLoaderSliber: ['sliber'],
  setLoaderMyTexts: ['myTexts'],
  setCountry: [
    'country',
    'phoneCode',
    'currency',
    'automatic',
    'organizationId',
    'latitude',
    'longitude',
  ],
  setCountryOrigin: ['countryOrigin'],
  changeCountry: ['countryChange'],
  deleteCountry: null,
  logout: null,
  setToken: ['tokenAuth'],
  setUniqueId: ['uniqueId'],
  updateUserData: ['userInfo', 'createService'],
  setUserData: ['userInfo'],
  setVerificationCode: ['verificationCode', 'register'],
  setSessionId: ['sessionId'],
  setLoggedIn: ['isLoggedIn'],
  setCodeError: ['codeError'],
  sendVerificationCode: ['phoneCode', 'phoneNumber', 'organizationId'],
  sendVerificationCodeEmail: [
    'phoneCode',
    'phoneNumber',
    'email',
    'organizationId',
  ],
  setLoginError: ['loginError'],
  alertTypeModal: ['bool'],
  setScroller: ['scroller', 'scrollerActive'],
})

export const SessionTypes = Types
export default Creators

export const INITIAL_STATE = Immutable({
  latitude: null,
  longitude: null,
  country: '',
  countryChange: false,
  phoneCode: null,
  currency: null,
  automatic: false,
  tokenAuth: '',
  uniqueId: null,
  user: null,
  sessionId: '',
  phoneNumber: '',
  email: '',
  isLoggedIn: false,
  codeError: '',
  organizationId: '',
  loginError: '',
  alertTypeModal: true,
  scroller: 0,
  scrollerActive: false,
  countryOrigin: null,
  vistas: [],
  banner: [],
  boxfoalts: [],
  ourClients: [],
  menu: [],
  blog: [],
  menuDonw: false,
  links: [],
  botonesMenu: [],
  contSliderLogistica: [],
  typeText: [],
  sliber: [],
  contSliderHitos: [],
  contSliderBlog: [],
  contArticuloTwoType: [],
  confFloatBlog: [],
  contTextTwoImg: [],
  contArticuloWhite: [],
  contVideo: [],
  contClientes: [],
  contArticulosh: [],
  contArticuloColum: [],
  fotter: [],
})

const request = state => state

const setCountry = (
  state,
  {
    country,
    phoneCode,
    currency,
    automatic,
    organizationId,
    latitude,
    longitude,
  },
) =>
  Immutable.merge(state, {
    country,
    phoneCode,
    currency,
    automatic,
    organizationId,
    latitude,
    longitude,
  })

const setCountryOrigin = (state, { countryOrigin }) =>
  Immutable.merge(state, {
    countryOrigin,
  })

const deleteCountry = state =>
  Immutable.merge(state, {
    country: null,
    phoneCode: null,
    currency: null,
    organizationId: 0,
  })

const changeCountry = (state, { countryChange }) =>
  Immutable.merge(state, {
    countryChange,
  })

const setLoaderVistas = (state, { vistas }) =>
  Immutable.merge(state, {
    vistas,
  })

const setLoaderBanner = (state, { banner }) =>
  Immutable.merge(state, {
    banner,
  })

const setLoaderBoxfoalts = (state, { boxfoalts }) =>
  Immutable.merge(state, {
    boxfoalts,
  })

const setLoaderBlog = (state, { blog }) =>
  Immutable.merge(state, {
    blog,
  })

const setLoaderOurClients = (state, { ourClients }) =>
  Immutable.merge(state, {
    ourClients,
  })

const setLoaderTypeText = (state, { typeText }) =>
  Immutable.merge(state, {
    typeText,
  })

const setLoaderMyTexts = (state, { myTexts }) =>
  Immutable.merge(state, {
    myTexts,
  })

const setLoaderMenu = (state, { menu }) =>
  Immutable.merge(state, {
    menu,
  })
const setLoaderFotter = (state, { fotter }) =>
  Immutable.merge(state, {
    fotter,
  })
const setMenuDonw = (state, { menuDonw }) =>
  Immutable.merge(state, {
    menuDonw,
  })
const setLoaderLinks = (state, { links }) =>
  Immutable.merge(state, {
    links,
  })
const setLoaderBotonesMenu = (state, { botonesMenu }) =>
  Immutable.merge(state, {
    botonesMenu,
  })
const setContSliderLogistica = (state, { contSliderLogistica }) =>
  Immutable.merge(state, {
    contSliderLogistica,
  })
const setContSliderHitos = (state, { contSliderHitos }) =>
  Immutable.merge(state, {
    contSliderHitos,
  })
const setContSliderBlog = (state, { contSliderBlog }) =>
  Immutable.merge(state, {
    contSliderBlog,
  })
const setContArticuloTwoType = (state, { contArticuloTwoType }) =>
  Immutable.merge(state, {
    contArticuloTwoType,
  })
const setConfFloatBlog = (state, { confFloatBlog }) =>
  Immutable.merge(state, {
    confFloatBlog,
  })
const setContArticuloColum = (state, { contArticuloColum }) =>
  Immutable.merge(state, {
    contArticuloColum,
  })
const setContTextTwoImg = (state, { contTextTwoImg }) =>
  Immutable.merge(state, {
    contTextTwoImg,
  })
const setContArticuloWhite = (state, { contArticuloWhite }) =>
  Immutable.merge(state, {
    contArticuloWhite,
  })
const setContVideo = (state, { contVideo }) =>
  Immutable.merge(state, {
    contVideo,
  })
const setContClientes = (state, { contClientes }) =>
  Immutable.merge(state, {
    contClientes,
  })
const setContArticulosh = (state, { contArticulosh }) =>
  Immutable.merge(state, {
    contArticulosh,
  })

const setLoaderSliber = (state, { sliber }) =>
  Immutable.merge(state, {
    sliber,
  })

const setToken = (state, { tokenAuth }) =>
  Immutable.merge(state, {
    tokenAuth,
  })
const setScroller = (state, { scroller, scrollerActive }) =>
  Immutable.merge(state, {
    scroller,
    scrollerActive,
  })
const setLoggedIn = (state, { isLoggedIn }) =>
  Immutable.merge(state, {
    isLoggedIn,
  })

const logout = state =>
  Immutable.merge(state, {
    tokenAuth: '',
    isLoggedIn: false,
    user: null,
  })

const setUniqueId = (state, { uniqueId }) =>
  Immutable.merge(state, {
    uniqueId,
  })

const setVerificationCode = (state, { verificationCode }) =>
  Immutable.merge(state, {
    verificationCode,
  })

const setSessionId = (state, { sessionId }) =>
  Immutable.merge(state, {
    sessionId,
  })

const alertTypeModal = (state, { bool }) =>
  Immutable.merge(state, {
    alertTypeModal: bool,
  })

const updateUserData = state => state

const setUserData = (state, { userInfo }) =>
  Immutable.merge(state, {
    user: {
      id: userInfo.id,
      name: userInfo.name,
      numDoc: userInfo.numDoc && userInfo.numDoc.toString(),
      email: userInfo.email,
      phone: userInfo.phone_number || userInfo.phone,
    },
  })

const sendVerificationCode = (state, { phoneNumber }) =>
  Immutable.merge(state, {
    phoneNumber,
  })
const setCodeError = (state, { codeError }) =>
  Immutable.merge(state, {
    codeError,
  })
const setLoginError = (state, { loginError }) =>
  Immutable.merge(state, {
    loginError,
  })

const sendVerificationCodeEmail = (state, { phoneNumber, email }) =>
  Immutable.merge(state, {
    phoneNumber,
    email,
  })

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_SESSION]: request,
  [Types.SET_LOCATION]: request,
  [Types.LOADER_VISTAS]: request,
  [Types.LOADER_BANNER]: request,
  [Types.LOADER_BOXFOALT]: request,
  [Types.LOADER_OUR_CLIENTS]: request,
  [Types.LOADER_MENU]: request,
  [Types.LOADER_FOTTER]: request,
  [Types.LOADER_BLOG]: request,
  [Types.LOADER_LINKS]: request,
  [Types.LOADER_BOTONES_MENU]: request,
  [Types.LOADER_CONT_SLIDER_LOGISTICA]: request,
  [Types.LOADER_CONT_SLIDER_HITOS]: request,
  [Types.LOADER_CONT_SLIDER_BLOG]: request,
  [Types.LOADER_CONT_ARTICULO_TWO_TYPE]: request,
  [Types.LOADER_CONT_ARTICULO_COLUM]: request,
  [Types.LOADER_CONT_ARTICULO_WHITE]: request,
  [Types.LOADER_CONT_TEXT_TWO_IMG]: request,
  [Types.LOADER_CONT_VIDEO]: request,
  [Types.LOADER_CONF_FLOAT_BLOG]: request,
  [Types.LOADER_CONT_CLIENTES]: request,
  [Types.LOADER_CONT_ARTICULOSH]: request,
  [Types.LOADER_TYPE_TEXT]: request,
  [Types.LOADER_SLIBER]: request,
  [Types.LOADER_MY_TEXTS]: request,
  [Types.SET_LOADER_BANNER]: setLoaderBanner,
  [Types.SET_LOADER_TYPE_TEXT]: setLoaderTypeText,
  [Types.SET_LOADER_MY_TEXTS]: setLoaderMyTexts,
  [Types.SET_LOADER_BOXFOALTS]: setLoaderBoxfoalts,
  [Types.SET_LOADER_BLOG]: setLoaderBlog,
  [Types.SET_LOADER_OUR_CLIENTS]: setLoaderOurClients,
  [Types.SET_LOADER_MENU]: setLoaderMenu,
  [Types.SET_LOADER_FOTTER]: setLoaderFotter,
  [Types.SET_MENU_DONW]: setMenuDonw,
  [Types.SET_LOADER_LINKS]: setLoaderLinks,
  [Types.SET_LOADER_BOTONES_MENU]: setLoaderBotonesMenu,
  [Types.SET_CONT_SLIDER_LOGISTICA]: setContSliderLogistica,
  [Types.SET_CONT_SLIDER_HITOS]: setContSliderHitos,
  [Types.SET_CONT_SLIDER_BLOG]: setContSliderBlog,
  [Types.SET_CONT_ARTICULO_TWO_TYPE]: setContArticuloTwoType,
  [Types.SET_CONF_FLOAT_BLOG]: setConfFloatBlog,
  [Types.SET_CONT_ARTICULO_COLUM]: setContArticuloColum,
  [Types.SET_CONT_TEXT_TWO_IMG]: setContTextTwoImg,
  [Types.SET_CONT_ARTICULO_WHITE]: setContArticuloWhite,
  [Types.SET_CONT_VIDEO]: setContVideo,
  [Types.SET_CONT_CLIENTES]: setContClientes,
  [Types.SET_CONT_ARTICULOSH]: setContArticulosh,
  [Types.SET_LOADER_SLIBER]: setLoaderSliber,
  [Types.SET_LOADER_VISTAS]: setLoaderVistas,
  [Types.SET_COUNTRY]: setCountry,
  [Types.DELETE_COUNTRY]: deleteCountry,
  [Types.CHANGE_COUNTRY]: changeCountry,
  [Types.SET_TOKEN]: setToken,
  [Types.SET_SCROLLER]: setScroller,
  [Types.SET_COUNTRY_ORIGIN]: setCountryOrigin,
  [Types.SET_LOGGED_IN]: setLoggedIn,
  [Types.SET_UNIQUE_ID]: setUniqueId,
  [Types.UPDATE_USER_DATA]: updateUserData,
  [Types.SET_USER_DATA]: setUserData,
  [Types.SET_VERIFICATION_CODE]: setVerificationCode,
  [Types.SET_SESSION_ID]: setSessionId,
  [Types.SEND_VERIFICATION_CODE]: sendVerificationCode,
  [Types.SEND_VERIFICATION_CODE_EMAIL]: sendVerificationCodeEmail,
  [Types.LOGOUT]: logout,
  [Types.SET_CODE_ERROR]: setCodeError,
  [Types.SET_LOGIN_ERROR]: setLoginError,
  [Types.ALERT_TYPE_MODAL]: alertTypeModal,
})
