import { call, select, put } from 'redux-saga/effects'
import { getSession } from '../reducers/selectors'
import HistoryActions from '../reducers/history'

export function* getHistory(api) {
  const { tokenAuth } = yield select(getSession)
  const response = yield call(api.getHistory, tokenAuth)
  switch (response.status) {
    case 200:
    case 201: {
      yield put(HistoryActions.setHistory(response.data))
      break
    }
    default: {
      break
    }
  }
}

export function* runService() {} // eslint-disable-line no-empty-function
