import { createReducer, createActions } from 'reduxsauce'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  toogleHelp: ['panel'],
  toogleDrawer: null,
})

export const ConnectionTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = {
  panels: {
    ServiceTypes: false,
    ServiceTasks: false,
    ServiceResume: false,
  },
  drawer: false,
}

/* ------------- Reducers ------------- */

const toogleHelp = (state, { panel }) => ({
  ...state,
  panels: {
    ...state.panels,
    ...panel,
  },
})

const toogleDrawer = state => ({
  ...state,
  drawer: !state.drawer,
})

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.TOOGLE_HELP]: toogleHelp,
  [Types.TOOGLE_DRAWER]: toogleDrawer,
})
