import { createStore, applyMiddleware, compose } from 'redux'
import createSegmentTracker from 'redux-segment-node'
import thunk from 'redux-thunk'
import createSagaMiddleware from 'redux-saga'
// import { persistStore, autoRehydrate } from 'redux-persist';
import rootReducer from '../reducers'
import mySaga from '../sagas'
import createHelpers from './createHelpers'
import createLogger from './logger'
import { REACT_APP_SEGMENT_TOKEN } from '../data/constants'

const segmentMiddleware = createSegmentTracker({
  key: REACT_APP_SEGMENT_TOKEN,
  flushAfter: 1000,
})

export default function configureStore(initialState, helpersConfig) {
  const sagaMiddleware = createSagaMiddleware()
  const helpers = createHelpers(helpersConfig)
  const middleware = [thunk.withExtraArgument(helpers), sagaMiddleware]
  let enhancer
  middleware.push(segmentMiddleware)
  if (__DEV__) {
    middleware.push(createLogger())

    let devToolsExtension = f => f
    if (process.env.BROWSER && window.devToolsExtension) {
      devToolsExtension = window.devToolsExtension()
    }

    enhancer = compose(
      applyMiddleware(...middleware),
      // autoRehydrate(),
      devToolsExtension,
    )
  } else {
    enhancer = compose(
      applyMiddleware(...middleware),
      // autoRehydrate(),
    )
  }
  // https://redux.js.org/docs/api/createStore.html
  const store = createStore(rootReducer, initialState, enhancer)

  // Hot reload reducers (requires Webpack or Browserify HMR to be enabled)

  // begin periodically persisting the store
  // persistStore(store);

  if (__DEV__ && module.hot) {
    module.hot.accept('../reducers', () =>
      // eslint-disable-next-line global-require
      store.replaceReducer(require('../reducers').default),
    )
  }

  sagaMiddleware.run(mySaga)

  return store
}
