import Moment from 'moment'

const advanceTime = (init, current, final) => {
  if (current.isSameOrAfter(final)) {
    return 100
  }
  const total = Moment.duration(final.diff(init)).asMinutes()
  const advance = Moment.duration(current.diff(init)).asMinutes()

  return (advance * 100) / total
}

const formatDate = taskDate => {
  let dateSet = Moment(new Date(taskDate))
  if (taskDate && taskDate.indexOf('Z') <= -1) {
    dateSet = dateSet.add(Moment().utcOffset(), 'minutes')
  }
  dateSet = dateSet.format('DD/MM/YYYY hh:mm a').split(' ')

  return {
    date: taskDate ? dateSet[0] : '',
    hour: taskDate ? dateSet[1].concat(' ', dateSet[2]) : '',
  }
}

const convertLatLng = coordinates => {
  const points = []
  coordinates.map(point =>
    points.push({ lat: point[1], lng: point[0] }),
  )

  return points
}

const getWaypoints = points => {
  const waypoints = [...points].splice(1, points.length - 2)
  if (waypoints.length >= 23) {
    const finalPoints = []
    let long = waypoints.length
    let index = 23
    while (index > 0 && long > 0) {
      long -= 2
      finalPoints.push(waypoints[long])
      index -= 1
    }
    return finalPoints.reverse()
  }
  return waypoints
}

const generateRoutes = (coordinates, resolve, failed) => {
  if (
    typeof window !== 'undefined' &&
    typeof window.google !== 'undefined'
  ) {
    const { maps } = window.google
    const directionsService = new maps.DirectionsService()
    if (coordinates.length > 0) {
      const points = getWaypoints(coordinates).map(point => ({
        location: point,
        stopover: true,
      }))
      const request = {
        origin: coordinates[0],
        destination: coordinates[coordinates.length - 1],
        optimizeWaypoints: false,
        waypoints: points,
        travelMode: maps.DirectionsTravelMode.DRIVING,
      }
      directionsService.route(request, (response, status) => {
        /* status === 'OK' ? resolve(response) : failed(response) */
        if (status === 'OK') {
          resolve(response)
        } else {
          failed(response)
        }
      })
    }
  }
}

const getRoutes = coordinates =>
  new Promise((resolve, reject) => {
    generateRoutes(
      coordinates,
      response => {
        resolve(response)
      },
      error => {
        reject(error)
      },
    )
  })

export { advanceTime, formatDate, convertLatLng, getRoutes }
