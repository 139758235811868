import { combineReducers } from 'redux'
import { reducer as service } from './service'
import { reducer as session } from './session'
import { reducer as tracking } from './tracking'
import { reducer as modal } from './modal'
import { reducer as history } from './history'
import { reducer as map } from './map'
import { reducer as animations } from './animations'
import { reducer as language } from './language'

export default combineReducers({
  service,
  session,
  tracking,
  modal,
  history,
  map,
  animations,
  language,
})
