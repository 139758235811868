import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

const { Types, Creators } = createActions({
  getHistory: null,
  setHistory: ['history'],
})

export const HistoryTypes = Types
export default Creators

export const INITIAL_STATE = Immutable({ history: [] })

const request = state => state

const setHistory = (state, { history }) =>
  Immutable.merge(state, {
    history,
  })

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_HISTORY]: request,
  [Types.SET_HISTORY]: setHistory,
})
