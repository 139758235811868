import { all, takeEvery, takeLatest } from 'redux-saga/effects'
import GEO_API from '../services/geoApi'
import API from '../services/api'
import IP_API from '../services/ip-api'
import API_STRAPI from '../services/apiStrapi'

import { TrackingTypes } from '../reducers/tracking'
import { ServiceTypes } from '../reducers/service'
import { SessionTypes } from '../reducers/session'
import { HistoryTypes } from '../reducers/history'

import {
  searchGeoSuggests,
  createService,
  createServiceFinal,
  createServiceLiftit,
  runService,
  searchByDirectAddress,
  updateService,
  setServiceFromId,
  sendConfirmationMessage,
  // notifyPubnub,
} from './service'
import {
  getUserLocation,
  setToken,
  identify,
  verifyCode,
  sendVerificationCode,
  sendVerificationCodeEmail,
  updateUser,
  logout,
  getLoaderVistas,
  getLoaderBanner,
  getLoaderTypeText,
  getLoaderMyTexts,
  getLoaderBoxfoalt,
  getLoaderBlog,
  getLoaderOurClients,
  getLoaderMenu,
  getLoaderFotter,
  getLoaderLinks,
  getLoaderBotonesMenu,
  getLoaderContSliderLogistica,
  getLoaderSliber,
  getLoaderContSliderHitos,
  getLoaderContSliderBlog,
  getLoaderContArticuloTwoType,
  getLoaderConfFloatBlog,
  getLoaderContArticuloColum,
  getLoaderContTextTwoImg,
  getLoaderContVideo,
  getLoaderContClientes,
  getLoaderContArticuloWhite,
  getLoaderContArticulosh,
} from './session'
import { getHistory } from './history'
import {
  setCurrentUuid,
  setServiceInfo,
  setDireccions,
  setChangeStatus,
  setRealTimeTracking,
} from './trackingSagas'
import onLocationScreen from './mixpanel'

const liftiApi = API.create()
const geoApi = GEO_API.create()
const ipApi = IP_API.create()
const apiStrapi = API_STRAPI

export default function* mySaga() {
  yield all([
    takeLatest(TrackingTypes.SET_CURRENT_UUID, setCurrentUuid),
    takeLatest(TrackingTypes.SET_RESPONSE, setServiceInfo),
    takeLatest(TrackingTypes.SET_COORDINATES, setDireccions),
    takeLatest('SET_CHANGE_STATUS_TASK', setChangeStatus),
    takeLatest(
      TrackingTypes.SET_REAL_TIME_TRACKING,
      setRealTimeTracking,
    ),
    takeEvery(
      ServiceTypes.SEARCH_GEO_SUGGEST,
      searchGeoSuggests,
      geoApi,
    ),
    takeLatest(
      ServiceTypes.SET_CURRENT_ORIGIN_ADDRESS,
      createServiceLiftit,
      liftiApi,
    ),
    takeLatest(
      ServiceTypes.SET_CURRENT_DESTINATION_ADDRESS,
      createServiceLiftit,
      liftiApi,
    ),
    takeLatest(SessionTypes.SET_LOCATION, getUserLocation, ipApi),
    takeLatest(SessionTypes.LOADER_VISTAS, getLoaderVistas, apiStrapi),
    takeLatest(SessionTypes.LOADER_BANNER, getLoaderBanner, apiStrapi),
    takeLatest(
      SessionTypes.LOADER_TYPE_TEXT,
      getLoaderTypeText,
      apiStrapi,
    ),
    takeLatest(
      SessionTypes.LOADER_MY_TEXTS,
      getLoaderMyTexts,
      apiStrapi,
    ),
    takeLatest(
      SessionTypes.LOADER_BOXFOALT,
      getLoaderBoxfoalt,
      apiStrapi,
    ),
    takeLatest(SessionTypes.LOADER_BLOG, getLoaderBlog, apiStrapi),
    takeLatest(
      SessionTypes.LOADER_OUR_CLIENTS,
      getLoaderOurClients,
      apiStrapi,
    ),
    takeLatest(SessionTypes.LOADER_MENU, getLoaderMenu, apiStrapi),
    takeLatest(SessionTypes.LOADER_FOTTER, getLoaderFotter, apiStrapi),
    takeLatest(SessionTypes.LOADER_LINKS, getLoaderLinks, apiStrapi),
    takeLatest(
      SessionTypes.LOADER_BOTONES_MENU,
      getLoaderBotonesMenu,
      apiStrapi,
    ),
    takeLatest(SessionTypes.LOADER_SLIBER, getLoaderSliber, apiStrapi),
    takeLatest(
      SessionTypes.LOADER_CONT_SLIDER_LOGISTICA,
      getLoaderContSliderLogistica,
      apiStrapi,
    ),
    takeLatest(
      SessionTypes.LOADER_CONT_SLIDER_HITOS,
      getLoaderContSliderHitos,
      apiStrapi,
    ),
    takeLatest(
      SessionTypes.LOADER_CONT_SLIDER_BLOG,
      getLoaderContSliderBlog,
      apiStrapi,
    ),
    takeLatest(
      SessionTypes.LOADER_CONT_ARTICULO_TWO_TYPE,
      getLoaderContArticuloTwoType,
      apiStrapi,
    ),
    takeLatest(
      SessionTypes.LOADER_CONF_FLOAT_BLOG,
      getLoaderConfFloatBlog,
      apiStrapi,
    ),
    takeLatest(
      SessionTypes.LOADER_CONT_ARTICULO_COLUM,
      getLoaderContArticuloColum,
      apiStrapi,
    ),
    takeLatest(
      SessionTypes.LOADER_CONT_ARTICULO_WHITE,
      getLoaderContArticuloWhite,
      apiStrapi,
    ),
    takeLatest(
      SessionTypes.LOADER_CONT_TEXT_TWO_IMG,
      getLoaderContTextTwoImg,
      apiStrapi,
    ),
    takeLatest(
      SessionTypes.LOADER_CONT_VIDEO,
      getLoaderContVideo,
      apiStrapi,
    ),
    takeLatest(
      SessionTypes.LOADER_CONT_CLIENTES,
      getLoaderContClientes,
      apiStrapi,
    ),
    takeLatest(
      SessionTypes.LOADER_CONT_ARTICULOSH,
      getLoaderContArticulosh,
      apiStrapi,
    ),

    takeLatest(ServiceTypes.CREATE_SERVICE, createService, liftiApi),
    takeLatest(
      ServiceTypes.CREATE_SERVICE_FINAL,
      createServiceFinal,
      liftiApi,
    ),
    takeLatest(ServiceTypes.RUN_SERVICE, runService, liftiApi),
    takeLatest(SessionTypes.SET_COUNTRY, setToken),
    takeLatest(
      ServiceTypes.SEARCH_BY_DIRECT_ADDRESS,
      searchByDirectAddress,
      geoApi,
    ),
    takeLatest(ServiceTypes.SET_SELECT_SERVICE, onLocationScreen),
    takeLatest(
      ServiceTypes.SET_SELECT_SERVICE,
      updateService,
      liftiApi,
    ),
    takeLatest(SessionTypes.SET_USER_DATA, identify),
    takeLatest(ServiceTypes.SET_SERVICE_FROM_ID, setServiceFromId),
    takeLatest(ServiceTypes.SET_PAYMENT_TYPE, createService, liftiApi),
    takeLatest(SessionTypes.SET_COUNTRY, setToken),
    takeLatest(
      ServiceTypes.SEND_CONFIRMATION_MESSAGE,
      sendConfirmationMessage,
      liftiApi,
    ),
    takeLatest(HistoryTypes.GET_HISTORY, getHistory, liftiApi),
    takeLatest(
      SessionTypes.SET_VERIFICATION_CODE,
      verifyCode,
      liftiApi,
    ),
    takeLatest(
      SessionTypes.SEND_VERIFICATION_CODE,
      sendVerificationCode,
      liftiApi,
    ),
    takeLatest(
      SessionTypes.SEND_VERIFICATION_CODE_EMAIL,
      sendVerificationCodeEmail,
      liftiApi,
    ),
    takeLatest(SessionTypes.UPDATE_USER_DATA, updateUser, liftiApi),
    takeLatest(SessionTypes.LOGOUT, logout),
  ])
}
